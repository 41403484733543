import { AriaLabelingProps, DOMProps } from '@react-types/shared';
import React, { createContext, PropsWithChildren } from 'react';
import { InputStylesProps } from 'registration-flow-v2/theme';

export type InputFieldProviderProps = PropsWithChildren<
  InputStylesProps & {
    fieldProps?: AriaLabelingProps & DOMProps;
  }
>;

export const InputFieldContext = createContext({} as InputFieldProviderProps);

export function InputFieldProvider(props: InputFieldProviderProps) {
  const { children, ...otherProps } = props;

  return <InputFieldContext.Provider value={otherProps}>{children}</InputFieldContext.Provider>;
}
