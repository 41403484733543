import React from 'react';
import { Message } from 'registration-flow-v2/message';
import { EValidationStatus, IValidations } from 'registration-flow-v2/types';

interface Props {
  /**
   * Rules of validation
   */
  validations: IValidations;
}

export const useMultiValidation = (props: Props) => {
  const { validations } = props;

  const messageTone = {
    [EValidationStatus.Uncheck]: 'normal',
    [EValidationStatus.Valid]: 'positive',
    [EValidationStatus.Invalid]: 'critical',
  };

  const isInvalid = validations?.some((validate) => validate.status === 'invalid');

  const validationFields = () => {
    if (!validations?.length) {
      return null;
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {validations?.length &&
          validations.map((error, index) => (
            <Message
              marginTop={index === 0 ? '$space125' : '$space50'}
              tone={messageTone[error.status] as 'default' | 'positive' | 'critical' | 'normal'}
              key={error.message}
            >
              {error.message}
            </Message>
          ))}
      </div>
    );
  };

  return { validationFields, isInvalid };
};
