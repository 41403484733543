// This file is old code, we don't change any thing, just move them to this file to easy to implement new UI. Please ignore this file when you review code.

import React, { useState } from 'react';
import * as H from 'history';
import { Icon, Input } from 'antd';

import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import { EmailAlreadyExistType } from 'utilities/enum-utils';
import { IRegistrationAccountInfo } from 'interfaces/auth-store-types';
import { loginPath } from 'utilities/navigation';

type LoginModalProperties = {
  visible: boolean;
  loginErrorType: EmailAlreadyExistType | null;
  history: H.History;
  onChangeLoginErrorType: (errorType: EmailAlreadyExistType | null) => void;
  onSubmitSuccess: (result: IRegistrationAccountInfo) => void;
  onClose: () => void;
};

const LoginModal = ({
  visible,
  loginErrorType,
  history,
  onClose,
  onChangeLoginErrorType,
  onSubmitSuccess,
}: LoginModalProperties) => {
  const dispatch = useDispatch<IRootDispatch>();

  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: '',
  });
  const [isLogging, setIsLogging] = useState(false);

  const goToLogin = () => {
    history.push(loginPath(), '_blank');
  };

  const goToSignUp = () => {
    history.push('/create-account/profile');
  };

  const login = async () => {
    try {
      setIsLogging(true);
      const result = await dispatch.authStore.doLogIn({ email: loginInfo.email, password: loginInfo.password });

      await onSubmitSuccess(result);
    } catch (error) {
      onChangeLoginErrorType(EmailAlreadyExistType.WAITING_TO_CREATE_ORGANISATION);
    } finally {
      setIsLogging(false);
    }
  };

  return (
    <ActionModal
      canCloseOutside={false}
      onClose={onClose}
      showHeader={true}
      isOpen={visible}
      showCloseButton={false}
      width="medium"
      title={
        loginErrorType === EmailAlreadyExistType.WAITING_TO_CREATE_ORGANISATION
          ? `Login details not found`
          : `It looks like you've already started creating your account!`
      }
    >
      {!loginErrorType ? (
        <div className={'mt-large mb-x-large'}>
          <Paragraph>
            To finish creating your account, please enter your login details below:
            <br />
          </Paragraph>
          <div>
            <Input
              size="large"
              placeholder="Enter your email here"
              allowClear
              value={loginInfo.email}
              onChange={(e) => setLoginInfo({ ...loginInfo, email: e.target.value })}
              prefix={<Icon type="user" className="text-color-tertiary" />}
            />

            <Input.Password
              size="large"
              placeholder="Password"
              className="mt-small"
              value={loginInfo.password}
              onChange={(e) => setLoginInfo({ ...loginInfo, password: e.target.value })}
              prefix={<Icon type="lock" className="text-color-tertiary" />}
            />
          </div>
        </div>
      ) : loginErrorType === EmailAlreadyExistType.ALREADY_LINKED ? (
        <div className={'mt-large mb-x-large'}>
          <Paragraph>
            Looks like your email is already linked to an existing organisation on GoodHuman. <br />
            Please log in with your email and password instead.
            <br />
          </Paragraph>
        </div>
      ) : (
        <div className={'mt-large mb-x-large'}>
          <Paragraph>
            Sorry, we were unable to find your login details. Please check that your email and password are correct and
            try again, or sign up for a new account.
            <br />
            <br />
            Still having trouble? <HyperlinkButton>Contact us</HyperlinkButton>
          </Paragraph>
        </div>
      )}
      <ActionModalFooter>
        {loginErrorType === EmailAlreadyExistType.WAITING_TO_CREATE_ORGANISATION && (
          <HyperlinkButton className="mr-medium" color="black" onClick={goToSignUp}>
            Sign up
          </HyperlinkButton>
        )}
        {!loginErrorType && (
          <PrimaryButton
            className="rounded-full"
            size="large"
            paddingSize="x-large"
            color="black"
            onClick={login}
            loading={isLogging}
          >
            Log in
          </PrimaryButton>
        )}
        {loginErrorType === EmailAlreadyExistType.WAITING_TO_CREATE_ORGANISATION && (
          <PrimaryButton
            className="rounded-full"
            size="large"
            paddingSize="x-large"
            color="black"
            onClick={() => onChangeLoginErrorType(null)}
          >
            Try again
          </PrimaryButton>
        )}
        {loginErrorType === EmailAlreadyExistType.ALREADY_LINKED && (
          <>
            <HyperlinkButton color="black" onClick={() => onChangeLoginErrorType(null)}>
              Try again
            </HyperlinkButton>
            <PrimaryButton
              className="ml-medium rounded-full"
              size="large"
              paddingSize="x-large"
              color="black"
              onClick={goToLogin}
            >
              Log in
            </PrimaryButton>
          </>
        )}
      </ActionModalFooter>
    </ActionModal>
  );
};

export default LoginModal;
