/** suppored ISO-3166 alpha-2 country codes. */
export type CountryCode = 'AU' | 'US';

export const countryCodes: CountryCode[] = ['AU', 'US'];

export const countryNames: Record<CountryCode, string> = {
  AU: 'Australia',
  US: 'United States',
};

export const countryCodePhonePrefix: Record<CountryCode, string> = {
  AU: '+61',
  US: '+1',
};
