import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import { boxStyleProps, css, useStyleProps } from '..';

import type { BoxStyleProps, HTMLProps, SlotProps } from '..';
import type { PropsWithChildren } from 'react';

export type BoxProps = PropsWithChildren<SlotProps & HTMLProps<HTMLDivElement> & BoxStyleProps>;

/**
 * @name
 * Box
 *
 * @description
 * Non semantic UI element with themed style props.
 *
 * @example
 * <Box padding="$medium">
 *   <Text>hello</Text>
 * </Box>
 *
 * // render `Box` as a `span`
 * <Box asChild>
 *   <span>
 *     <Text>Hello, GoodHuman</Text>
 *   </span>
 * </Box>
 */
export const Box = forwardRef<HTMLDivElement, BoxProps>(function Box(props, ref) {
  let { styleProps, ...otherProps } = useStyleProps(props, boxStyleProps);
  let { asChild, children } = props;
  let ElementType = asChild ? Slot : 'div';

  return (
    <ElementType {...filterDOMProps(otherProps)} className={css(styleProps)()} ref={ref}>
      {children}
    </ElementType>
  );
});
