import { logoTextSmall } from 'assets/logo';
import React from 'react';
import { css, ProgressBar } from 'registration-flow-v2';
import { INVITED_STEP_DETAILS, REGISTRATION_STEP_DETAILS, SCREEN_TYPE } from '../constants';
import * as styles from '../css/css-components/FormWrapperSection.css';

interface IProps {
  children?: React.ReactNode;
  step?: string;
  screenType?: string;
  defaultValue?: number;
  hasProgressBar?: boolean;
  isLoadingForm?: boolean;
}

const FormWrapperSection = (props: IProps) => {
  const { children, step, screenType, defaultValue, hasProgressBar, isLoadingForm } = props;

  const calculateProgressValue = (screenTypeStepDetail) => {
    const steps = Object.keys(screenTypeStepDetail).length;
    return (100 / steps) * screenTypeStepDetail[step].step;
  };

  let progressValue = 0;

  switch (screenType) {
    case SCREEN_TYPE.REGISTRATION_FLOW: {
      progressValue = calculateProgressValue(REGISTRATION_STEP_DETAILS);
      break;
    }
    case SCREEN_TYPE.INVITED_MEMBER: {
      progressValue = calculateProgressValue(INVITED_STEP_DETAILS);
      break;
    }
    default: {
      progressValue = defaultValue;
      break;
    }
  }

  return (
    <div className={css(styles.formContainer)()} style={{ transform: !isLoadingForm && 'translateZ(1px)' }}>
      {hasProgressBar && (
        <div className={css(styles.progressBar)()}>
          <ProgressBar
            value={progressValue}
            fillColor="$yellow"
            trackColor="$white"
            height="inherit"
            aria-label="registration-progress"
          />
        </div>
      )}
      <div className={css(styles.formDetails)()}>
        <img src={logoTextSmall} className={css(styles.smallLogo)()} />
        {children}
      </div>
    </div>
  );
};

export default FormWrapperSection;
