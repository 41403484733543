import React from 'react';
import { Text } from '@good/components';
import { Briefcase } from '@good/icons';

export const ServiceProviderCardSkeleton = () => {
  return (
    <button
      disabled
      className="rounded-lg border-2 border-gray-light-2 p-7 hover:border-accent hover:bg-accent-weak focus:border-accent"
      data-testid="service-provider-card-skeleton"
    >
      <div role="status" className="animate-pulse">
        <Text size="lg" className="flex items-center gap-5 font-bold">
          <Briefcase className="stroke-2" data-testid="briefcase-icon" />
          <div className="h-4 w-48 rounded-full bg-gray"></div>
        </Text>
      </div>
    </button>
  );
};
