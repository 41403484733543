import { CSS } from 'registration-flow-v2';

export const form: CSS = {
  display: 'flex',
  flexDirection: 'column',
};

export const title: CSS = {
  marginBottom: '$space300',
  fontSize: '$font400',
  fontWeight: '$normal',
  fontFamily: '$sans',
  color: '$black',
  '@initial': {
    lineHeight: '$leading650',
  },
  '@small': {
    lineHeight: '$leading800',
  },
};

export const gmail: CSS = {
  fontWeight: '$bold',
};

export const button: CSS = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$space100',

  width: '$sizeFull',
  height: '$size700',
  backgroundColor: '$white',
  marginBottom: '$space300',
  cursor: 'pointer',

  borderStyle: '$solid',
  borderWidth: '$border150',
  borderColor: '$bodyLight1',
  borderRadius: '$small',
  padding: '$space200',
  textAlign: 'center',
};

export const text: CSS = {
  fontFamily: '$sans',
  fontWeight: '$normal',
  fontSize: '$font100',
  lineHeight: '$leading400',
  color: '$bodyDark2',
};

export const moreInfo: CSS = {
  marginBottom: '$space450',
  fontFamily: '$sans',
  fontWeight: '$normal',
  fontSize: '$font75',
  lineHeight: '$leading400',
  color: '$bodyDark2',
};

export const textReEnterEmail: CSS = {
  fontWeight: '$bold',
  textDecorationLine: 'underline',
  cursor: 'pointer',
  color: '#206E8C',
};

export const artWordWrapper: CSS = {
  margin: '0 auto',
  width: '124px',
  height: '124px',
  backgroundColor: '$yellow',
  borderRadius: '100%',
  position: 'relative',
};

export const paperPlane: CSS = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  width: '$size2000',
};
