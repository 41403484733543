/* eslint-disable */
import React, { Component } from 'react';
import { Button, Card, Divider, Icon, message, Typography } from 'antd';
import firebaseApp from 'stores/firebase-app';

import { connect } from 'react-redux';
import _ from 'lodash';
import { IRootState } from 'stores/rematch/root-store';

const { Text } = Typography;

class ProfileMenu extends Component<any, any> {
  _onClickLogout = async () => {
    // const dismissMessage = await message.loading('Signing out...', 1);
    await message.loading('Signing out...', 1);
    await firebaseApp.auth.signOut();

    this.props.setCurrentUser(null);
    this.props.setCurrentPortalUser(null);
    this.props.setPortalUserList(null);

    message.success('Signed out.');
  };

  render() {
    const { currentUser, history, portalUser } = this.props;

    return (
      <Card style={{ marginTop: '-5px', marginRight: '4px' }} bodyStyle={{ padding: '0px' }}>
        {/*<div style={{ marginLeft: '16px' }} />*/}
        <div style={{ padding: '8px 24px' }}>
          <Text strong>Hello, {portalUser.firstName && portalUser.firstName}!</Text>
        </div>
        <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />
        <div style={{ padding: '8px 24px' }}>
          <Text>
            Signed in to <Text strong>{portalUser.serviceProviderName}</Text>
          </Text>
          <br />
          <Text>
            Role: <Text strong>{portalUser.role && _.capitalize(portalUser.role)}</Text>
          </Text>
        </div>
        <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />

        <div style={{ padding: '8px' }}>
          <Button type={'link'} onClick={() => history.push('/my-profile')}>
            <Icon type={'user'} /> View My Profile
          </Button>
          <br />
          <Button type={'link'} onClick={this._onClickLogout}>
            <Icon type={'logout'} /> Sign out
          </Button>
        </div>
        {/*<Menu>*/}
        {/*  <Menu.Item>*/}
        {/*    <Icon type={'user'} /> View My Profile*/}
        {/*  </Menu.Item>*/}
        {/*  <Menu.Item onClick={() => this._onClickLogout()}>*/}
        {/*    <Icon type={'logout'} /> Sign out*/}
        {/*  </Menu.Item>*/}
        {/*</Menu>*/}
      </Card>
    );
  }
}

const mapState = (state: IRootState) => ({
  authStore: state.authStore,
  currentUser: state.authStore.currentUser,
});

const mapDispatch = (dispatch) => ({
  // setAppLoaded: dispatch.authStore.setAppLoaded,
  setCurrentUser: dispatch.authStore.setCurrentUser,
  setCurrentPortalUser: dispatch.authStore.setCurrentPortalUser,
  setPortalUserList: dispatch.authStore.setPortalUserList,
});

export default connect(mapState, mapDispatch)(ProfileMenu);
