import React from 'react';
import { Text } from '@good/components';
import { Briefcase, Loader2Adjustable } from '@good/icons';

type Properties = {
  serviceProviderName: string;
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
};
export const ServiceProviderCard = ({ onClick, isLoading, isDisabled, serviceProviderName }: Properties) => {
  return (
    <button
      disabled={isDisabled}
      className="rounded-lg border-2 border-gray-light-2 p-7 hover:border-accent hover:bg-accent-weak focus:border-accent"
      onClick={onClick}
      data-testid="service-provider-card"
    >
      <Text size="lg" className="flex items-center gap-5 font-bold">
        {isLoading ? (
          <Loader2Adjustable className="animate-spin" data-testid="loading-icon" />
        ) : (
          <Briefcase className="stroke-2" data-testid="briefcase-icon" />
        )}
        {serviceProviderName}
      </Text>
    </button>
  );
};
