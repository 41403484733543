import React from 'react';
import { css } from 'registration-flow-v2';
import { Link, Text } from '@good/components';
import * as styles from '../css/css-components/Footer.css';

const Footer = () => {
  return (
    <Text asChild>
      <footer>
        <div className={css(styles.footer)()}>
          <Link className="text-base" emphasis="no-underline">
            <a href="https://goodhuman.me/terms-of-use/" target="_blank">
              Terms
            </a>
          </Link>
          <Link className="text-base" emphasis="no-underline">
            <a href="https://goodhuman.me/sitemap/" target="_blank">
              Sitemap
            </a>
          </Link>
          <Link className="text-base" emphasis="no-underline">
            <a href="https://goodhuman.me/privacy/" target="_blank">
              Privacy
            </a>
          </Link>
          <Link className="text-base" emphasis="no-underline">
            <a href="https://www.goodhuman.me/" target="_blank">
              GoodHuman ©
            </a>
          </Link>
        </div>
      </footer>
    </Text>
  );
};

export default Footer;
