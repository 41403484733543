import React from 'react';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';

import { Alert, css, Link, Text } from 'registration-flow-v2';
import { EmailAlreadyExistType } from 'utilities/enum-utils';
import * as styles from 'views/css/css-components/AccountInformationSection.css';
import { IExistingDomainData } from '../interfaces';

type IAccountInformationProps = {
  emailAlreadyExistType: string;
  email: string;
  sendEmail: () => void;
  goToLogin: () => void;
  alertActionDisabled: boolean;
  setEmailAlreadyExistType: (value: string) => void;
  setExistingDomainData: (value: IExistingDomainData) => void;
  createUser?: () => void;
  openRequestAccessModal?: () => void;
};

const AlreadyLinkedAlert = ({ email, goToLogin, setEmailAlreadyExistType, setExistingDomainData }) => {
  const notDesktopDevice = isMobile || isTablet;

  const handleClose = () => {
    setEmailAlreadyExistType(null);
    setExistingDomainData(null);
  };

  return (
    <Alert
      title="Already have an account?"
      kind="inline"
      emphasis="filled"
      actions={!notDesktopDevice && <Link onPress={goToLogin}>Log in</Link>}
      marginBottom="$space300"
      tone="warning"
      onClose={handleClose}
    >
      {isDesktop || isTablet ? 'Already have an account?' : ''} Log in to <Text emphasis="bold">{email}</Text>{' '}
      {notDesktopDevice ? 'on your desktop for the best experience.' : ''}
    </Alert>
  );
};

const WaitingToCreateOrganisationAlert = ({
  email,
  sendEmail,
  setEmailAlreadyExistType,
  setExistingDomainData,
  alertActionDisabled,
}) => {
  const handleClose = () => {
    setEmailAlreadyExistType(null);
    setExistingDomainData(null);
  };

  return (
    <Alert
      title="Already started signing up?"
      kind="inline"
      emphasis="filled"
      actions={
        <Link isDisabled={alertActionDisabled} onPress={sendEmail}>
          Resend verification
        </Link>
      }
      marginBottom="$space300"
      tone="warning"
      onClose={handleClose}
    >
      Looks like someone has already registered <Text emphasis="bold">{email}</Text> with us. If this was you, hit
      Resend verification email to first verify it's you and continue creating your GoodHuman for business account.
    </Alert>
  );
};

const ExistingDomainAlert = ({
  email,
  createUser,
  openRequestAccessModal,
  setEmailAlreadyExistType,
  setExistingDomainData,
  alertActionDisabled,
}) => {
  const handleClose = () => {
    setEmailAlreadyExistType(null);
    setExistingDomainData(null);
  };

  return (
    <Alert
      title="Domain already registered"
      kind="inline"
      emphasis="filled"
      actions={
        <>
          <Link isDisabled={alertActionDisabled} whiteSpace="nowrap" onPress={openRequestAccessModal}>
            Request access
          </Link>
          <Link isDisabled={alertActionDisabled} whiteSpace="nowrap" onPress={createUser}>
            Set up new workspace
          </Link>
        </>
      }
      marginBottom="$space300"
      tone="warning"
      onClose={handleClose}
    >
      Looks like someone with the email domain {email.split('@')[1]} has already created a GoodHuman account and
      workspace.
      <blockquote className={css(styles.quote)()}>
        You can either:
        <ol className={css(styles.steps)()}>
          <li>
            Request access to the existing company workspace (you can use the same workspace even if you’re setting up a
            new department)
          </li>
          <li>Start again – set up another workspace (the two won’t be linked in any way)</li>
        </ol>
      </blockquote>
    </Alert>
  );
};

const AccountInformationAlert = ({
  emailAlreadyExistType,
  email,
  sendEmail,
  goToLogin,
  setExistingDomainData,
  setEmailAlreadyExistType,
  openRequestAccessModal,
  createUser,
  alertActionDisabled,
}: IAccountInformationProps) => {
  switch (emailAlreadyExistType) {
    case EmailAlreadyExistType.ALREADY_LINKED:
      return (
        <AlreadyLinkedAlert
          email={email}
          goToLogin={goToLogin}
          setExistingDomainData={setExistingDomainData}
          setEmailAlreadyExistType={setEmailAlreadyExistType}
        />
      );

    case EmailAlreadyExistType.WAITING_TO_CREATE_ORGANISATION:
      return (
        <WaitingToCreateOrganisationAlert
          email={email}
          sendEmail={sendEmail}
          alertActionDisabled={alertActionDisabled}
          setExistingDomainData={setExistingDomainData}
          setEmailAlreadyExistType={setEmailAlreadyExistType}
        />
      );

    default:
      return (
        <ExistingDomainAlert
          email={email}
          createUser={createUser}
          alertActionDisabled={alertActionDisabled}
          openRequestAccessModal={openRequestAccessModal}
          setExistingDomainData={setExistingDomainData}
          setEmailAlreadyExistType={setEmailAlreadyExistType}
        />
      );
  }
};

export default AccountInformationAlert;
