import { logoTextLarge } from 'assets/logo';
import WomenPhone from 'assets/women-phone.svg';
import React from 'react';
import { compose, css } from 'registration-flow-v2';
import {
  INVITED_STEPS,
  REGISTRATION_STEPS,
  REGISTRATION_STEP_DETAILS,
  SCREEN_TYPE,
  WORKSPACE_SIGN_IN_STEPS,
  WORKSPACE_SIGN_IN_STEP_DETAILS,
  INVITED_STEP_DETAILS,
  PASSWORD_RESET_STEPS,
  PASSWORD_RESET_STEP_DETAILS,
} from '../constants';
import * as styles from '../css/css-components/LeftSideBarSection.css';
import Footer from './Footer';

type IProps =
  | { screenType: SCREEN_TYPE.REGISTRATION_FLOW; step: REGISTRATION_STEPS }
  | { screenType: SCREEN_TYPE.INVITED_MEMBER; step: INVITED_STEPS }
  | { screenType: SCREEN_TYPE.WORKSPACE_SIGN_IN; step: WORKSPACE_SIGN_IN_STEPS }
  | { screenType: SCREEN_TYPE.PASSWORD_RESET; step: PASSWORD_RESET_STEPS };

const circleSize = ({ screenType, step }: IProps) => {
  if (screenType === SCREEN_TYPE.REGISTRATION_FLOW) return REGISTRATION_STEP_DETAILS[step].circleSize;
  if (screenType === SCREEN_TYPE.INVITED_MEMBER) return INVITED_STEP_DETAILS[step].circleSize;
  if (screenType === SCREEN_TYPE.PASSWORD_RESET) return PASSWORD_RESET_STEP_DETAILS[step].circleSize;
  return WORKSPACE_SIGN_IN_STEP_DETAILS[step].circleSize;
};

const LeftSideBarSection = (props: IProps) => {
  const expandingCircleSize = circleSize(props);

  return (
    <div className={css(styles.leftSideBarContainer)()}>
      <div className={css(styles.leftSideBar)()}>
        <img src={logoTextLarge} className={css(styles.logo)()} alt="GoodHuman logo" />
        <div className={css({ position: 'relative', alignItems: 'flex-end' })()}>
          <div className={compose(styles.variants({ size: expandingCircleSize }))} />
          <img className={css(styles.person)()} src={WomenPhone} alt="Woman using mobile phone" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LeftSideBarSection;
