import { FocusableElement } from '@react-types/shared';
import type { DOMAttributes, PropsWithChildren } from 'react';
import React from 'react';
import type { BaseStyleProps, HTMLProps } from '../..';
import { Button, compose, css, Text, useStyleProps } from '../..';
import { Cross } from '../../icon';
import * as styles from './modal-header.css';

export type ModalHeaderProps = PropsWithChildren<BaseStyleProps & HTMLProps<HTMLDivElement>> & {
  /**
   * Title props of react-aria - useDialog()
   */
  titleProps?: DOMAttributes<FocusableElement>;

  /**
   *  Whether a close (x) button is visible on top right of the modal dialog or not
   */
  closable?: boolean;

  /**
   * Close handle when trigger close button
   */
  onClose?(): void;

  /**
   * Custom close icon for modal.
   * Ex: <Modal {...otherProps} closeIcon={<Icon/>} >{...children}</Modal>
   */
  closeIcon?: React.ReactNode;
};

/**
 * @name
 * Modal Header
 *
 * @example
 * <ModalHeader closable onClose={()=>{}} >
 *    Modal title
 * </ModalHeader>
 */
export const ModalHeader = (props: ModalHeaderProps) => {
  const { children, closable, titleProps, closeIcon, onClose } = props;

  const { styleProps } = useStyleProps(props);

  // if (!children) {
  //   return null;
  // }

  return (
    <div {...titleProps} className={compose(css(styles.reset, styles.modalHeader), css(styleProps))}>
      {typeof children === 'string' ? (
        <Text color="$black" size="large" emphasis="bold" lineHeight={closable ? '' : '$leading950 !important'}>
          {children}
        </Text>
      ) : (
        children
      )}
      {closable && (
        <Button
          onPress={onClose}
          paddingLeft="$space100"
          paddingRight="$space100"
          emphasis="quiet"
          marginLeft="auto"
          onlyIcon
        >
          {closeIcon ?? <Cross width="$size300" height="$size300" />}
        </Button>
      )}
    </div>
  );
};
