import { CSS } from 'registration-flow-v2/theme/index';

export const form: CSS = {
  display: 'flex',
  flexDirection: 'column',
};

export const title: CSS = {
  marginBottom: '$space300',
  fontSize: '$font400',
  fontWeight: '$normal',
  fontFamily: '$sans',
};

export const description: CSS = {
  fontSize: '$font75',
  lineHeight: '$leading400',
  marginTop: '$space125',
  marginBottom: '$space400',
  fontFamily: '$primary',
};

export const expireDate: CSS = {
  fontWeight: '$bold',
};

export const tooltipWrapper: CSS = {
  width: '358px',
  padding: '$medium $large $medium $medium',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '$xsmall',
  borderRadius: '$small',
  color: '$white',
  backgroundColor: '$lavenderDark2',
};

export const tooltipDescription: CSS = {
  fontSize: '$font50',
  fontWeight: '$normal',
  lineHeight: '$leading300',
};
