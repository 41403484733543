import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import * as styles from './inline.css';
import { boxStyleProps, compose, css, useStyleProps } from '..';

import type { BoxStyleProps, HTMLProps, SlotProps } from '..';
import type { PropsWithChildren } from 'react';

export type InlineProps = SlotProps &
  HTMLProps<HTMLDivElement> &
  BoxStyleProps &
  PropsWithChildren<{
    collapseAt?: any;
  }>;

/**
 * @name
 * Inline
 *
 * @description
 * The Inline component is designed to create consistent spacing
 * around inline elements of variable width. The items in the
 * Inline component will only wrap if specified.
 */
export const Inline = forwardRef<HTMLDivElement, InlineProps>(function Inline(props, ref) {
  let { styleProps, ...otherProps } = useStyleProps(props, boxStyleProps);
  let { asChild, children, collapseAt } = props;
  let ElementType = asChild ? Slot : 'div';

  return (
    <ElementType
      {...filterDOMProps(otherProps)}
      className={compose(
        css(styles.reset, styles.inline, styleProps, {
          [`@media(max-width: ${collapseAt})`]: {
            flexDirection: 'column',
          },
        }),
      )}
      ref={ref}
    >
      {children}
    </ElementType>
  );
});
