import React from 'react';
import FormWrapperSection from '../components/FormWrapperSection';
import * as commonStyles from '../css/common-onboarding.css';
import { css } from 'registration-flow-v2';
import { Button, Heading, Text } from '@good/components';
import LeftSideBarSection from '../components/LeftSideBarSection';
import { INVITED_STEPS, SCREEN_TYPE } from '../constants';
import { reactTrpc } from 'utilities/react-trpc';
import { Redirect, useHistory } from 'react-router-dom';
import { useSearchParam } from 'hooks/use-search-param';
import { message } from 'antd';
import { Loader2Adjustable } from '@good/icons';
import { InvitationAcceptButton } from './components/invitation-accept-button';

export const InvitationView = (): JSX.Element => {
  const history = useHistory();
  const token = useSearchParam('token');
  if (!token) {
    void message.warn('Missing invitation token');
    return <Redirect to="/login" />;
  }

  const { data, error: invitationError } = reactTrpc.invitations.fetch.useQuery({
    encryptedToken: token,
  });
  const invitation = data?.found ? data.invitation : null;

  return (
    <div className={css(commonStyles.registrationContainer)()}>
      <LeftSideBarSection screenType={SCREEN_TYPE.INVITED_MEMBER} step={INVITED_STEPS.START_INVITED} />

      <FormWrapperSection>
        <div className="flex flex-col gap-10">
          {invitationError || (data && !data.found) ? (
            <>
              <div className="flex flex-col gap-2">
                <Heading size="lg">Whoops!</Heading>
                <Text
                  size="md"
                  className="font-medium"
                >{`We couldn't find the details of your invite. Please contact your administrator.`}</Text>
              </div>

              <Button onClick={() => history.push('/login')}>Return to login</Button>
            </>
          ) : invitation ? (
            <>
              <div className="flex flex-col gap-2">
                <Heading size="lg">Welcome {invitation.firstName},</Heading>
                <Text size="xl" className="font-medium">
                  {`You've been invited to join ${invitation.serviceProviderName}`}
                </Text>
              </div>
              <div className="flex flex-col gap-4">
                <InvitationAcceptButton token={token} />
              </div>
            </>
          ) : (
            <div className="align-center flex gap-2">
              <Loader2Adjustable className="animate-spin" data-testid="loading-icon" />
              <Text size="md" className="font-medium">
                Loading invitation details...
              </Text>
            </div>
          )}
        </div>
      </FormWrapperSection>
    </div>
  );
};
