import apiClient from 'utilities/api-client';
import Axios, { AxiosRequestConfig } from 'axios';
import { SetupIntent, SetupIntentResult } from '@stripe/stripe-js';
import { PaymentMethod } from '@stripe/stripe-js/types/api/payment-methods';

import { STRIPE_CONFIG } from 'variables/app-config';

interface IStripeStoreState {
  setupIntent: any;
  clientSecret: string;
  subscription: any;
}
const initialState: IStripeStoreState = { setupIntent: null, clientSecret: null, subscription: null };

const stripeStore = {
  state: initialState,
  reducers: {
    setStripe: (state, payload) => {
      return {
        ...state,
        ...payload,
      };
    },
    setSetupIntent: (state, payload) => ({ ...state, setupIntent: payload }),
    setClientSecret: (state, payload) => ({ ...state, clientSecret: payload }),
  },
  effects: (dispatch) => ({
    async doCreateSetupIntent(payload: {
      data: { payment_method_types: string[]; customer: string };
    }): Promise<SetupIntentResult> {
      try {
        const response = await apiClient.post(`/api/stripe/createSetupIntent`, payload);

        if (response) {
          this.setSetupIntent(response.data);
        } else {
          return null;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doUpdateSetupIntent(payload: Partial<SetupIntent>): Promise<SetupIntentResult> {
      try {
        const response = await apiClient.post(`/api/stripe/updateSetupIntent`, payload);

        if (response) {
          return response.data;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doCancelSetupIntent(payload: { setupIntentId: string }): Promise<unknown> {
      try {
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: 'Bearer ' + STRIPE_CONFIG.STRIPE_PUBLISHABLE_KEY,
          },
        };
        const { setupIntentId } = payload;
        const response = await Axios.post(
          `https://api.stripe.com/v1/setup_intents/${setupIntentId}/cancel`,
          null,
          config,
        );
        return response ? response.data : null;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doUpdateCustomer(payload: { stripeCustomerId: string; data: { name: string } }): Promise<unknown> {
      try {
        const response = await apiClient.post(`/api/stripe/updateStripeCustomer`, payload);
        return response ? response.data : null;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doDeleteCustomer(payload: { stripeCustomerId: string }): Promise<unknown> {
      try {
        const response = await apiClient.delete(`/api/stripe/deleteStripeCustomer/${payload}`);
        return response ? response.data : null;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doGetSubscriptionList(payload: { customer: string; limit: number }): Promise<unknown> {
      try {
        const response = await apiClient.post(`/api/stripe/getSubscriptionList`, payload);
        if (response) {
          return response.data;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doCreateSubscription(payload: {
      data: {
        customer: string;
        items: { price: string }[];
        default_tax_rates?: string[];
        default_payment_method: string | null | PaymentMethod;
      };
    }): Promise<{ id: string }> {
      try {
        const response = await apiClient.post(`/api/stripe/createSubscription`, payload);
        if (response) {
          return response.data;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doUpdateSubscriptionItem(payload: {
      data: {
        customer: string;
        items: { price: string }[];
        default_payment_method: string;
      };
    }): Promise<unknown> {
      try {
        const response = await apiClient.post(`/api/stripe/updateSubscriptionItem`, payload);
        if (response) {
          return response.data;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  }),
};

export default stripeStore;
