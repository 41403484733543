import type { CSS } from '../../theme';

export const reset: CSS = {
  boxSizing: 'border-box',
  outline: 'none',
};

export const modalHeader: CSS = {
  height: '76px',

  position: 'relative',
  display: 'flex',
  fontFamily: '$primary',
  fontSize: '$font75',
  alignItems: 'center',

  paddingLeft: '$space300',
  paddingRight: '$space300',
  paddingTop: '$space200',
  paddingBottom: '$space200',

  borderTopLeftRadius: '$xsmall',
  borderTopRightRadius: '$xsmall',
  borderBottomStyle: '$solid',
  borderBottomColor: '$bodyLight2',
  borderBottomWidth: '$border200',
};
