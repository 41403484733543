import React from 'react';
import { Icon } from '../icon';
import type { IconProps } from '../icon-props';

export function Info(props: IconProps) {
  return (
    <Icon {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM10 8.54167C10.3452 8.54167 10.625 8.82149 10.625 9.16667V13.3333C10.625 13.6785 10.3452 13.9583 10 13.9583C9.65482 13.9583 9.375 13.6785 9.375 13.3333V9.16667C9.375 8.82149 9.65482 8.54167 10 8.54167ZM10 7.5C10.4602 7.5 10.8333 7.1269 10.8333 6.66667C10.8333 6.20643 10.4602 5.83333 10 5.83333C9.53976 5.83333 9.16667 6.20643 9.16667 6.66667C9.16667 7.1269 9.53976 7.5 10 7.5Z'
        fill='currentColor'
      />
    </Icon>
  );
}
