import { CSS } from 'registration-flow-v2';

export const form: CSS = {
  display: 'flex',
  flexDirection: 'column',
};

export const description: CSS = {
  fontFamily: '$sans',
  fontSize: '$font75',
  lineHeight: '$leading400',
  fontWeight: '$normal',
  marginTop: '$space125',
  marginBottom: '$space400',
  color: '$bodyDark2',
};

export const expireDate: CSS = {
  fontWeight: '$bold',
};

export const moreInfo: CSS = {
  marginTop: '$space225',
  fontWeight: '$bold',
  color: '$bodyDark2',
  fontFamily: '$primary',
  fontSize: '$font75',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const logIn: CSS = {
  color: '$oceanDark1',
  cursor: 'pointer',
  fontSize: '$font75',
};

export const quote: CSS = {
  marginTop: '$space100',
};

export const steps: CSS = {
  marginTop: '$space100',
  paddingLeft: '$space225',
};

export const eyeIcon: CSS = {
  cursor: 'pointer',
};
