import React, { createContext } from 'react';

import type { Ref } from 'react';
import type { IconProps } from './icon-props';

type IconElement = Ref<SVGElement>;
export type IconProviderProps = IconProps & {
  iconRef?: IconElement;
};

export const IconContext = createContext<IconProviderProps>({});

export function IconProvider(props: IconProviderProps) {
  const { children, ...otherProps } = props;

  return <IconContext.Provider value={otherProps}>{children}</IconContext.Provider>;
}
