import React, { useState } from 'react';
import { Box, Stack, Title, Text, Anchor, TextInput, Button } from '@good/ui/core';
import { useForm } from '@good/ui/form';
import { reactTrpc } from 'utilities/react-trpc';
import { getSamlProvider } from 'providers/saml-provider';
import { signInWithPopup } from 'firebase/auth';
import firebaseApp from 'stores/firebase-app';
import { message } from 'antd';

type FormProps = {
  onGoBack: () => void;
};

enum LoginState {
  INITIAL = 'INITIAL',
  LOGGING_IN = 'LOGGING_IN',
  NO_PROVIDER = 'NO_PROVIDER',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
}

export function VerifySamlEmailForm(props: FormProps) {
  const [loginState, setLoginState] = useState<LoginState>(LoginState.INITIAL);

  const { onGoBack } = props;

  const form = useForm({
    initialValues: { email: '' },
    validate: {
      email: (value) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? null : 'Invalid email'),
    },
  });

  const { mutate: lookupSaml } = reactTrpc.saml.lookup.useMutation({
    onSuccess: async (data) => {
      const [provider] = data;
      if (!provider) {
        setLoginState(LoginState.NO_PROVIDER);
        return;
      }

      const { auth } = firebaseApp;
      if (auth.currentUser) await auth.signOut();

      setLoginState(LoginState.LOGGING_IN);

      const samlProvider = getSamlProvider(provider?.samlProviderId as string);
      const results = await signInWithPopup(auth, samlProvider);

      setLoginState(LoginState.LOGIN_SUCCESS);
      await message.success(`Successfully logged in with ${results.user.email}`);
    },
  });

  function onValidEmail(values: { email: string }) {
    const { email } = values;
    lookupSaml({ email });
  }

  return (
    <div>
      <Box className='inline-block' mb='lg'>
        <Anchor onClick={onGoBack}>Back</Anchor>
      </Box>

      {(loginState === LoginState.INITIAL || loginState === LoginState.NO_PROVIDER) && (
        <form onSubmit={form.onSubmit(onValidEmail)}>
          <Stack gap='xl'>
            <Box>
              <Title order={2} fw='normal' mb='xs'>
                Sign in using SSO
              </Title>
              <Text>Please enter your work email & we&#39;ll login with your registered SSO provider.</Text>
            </Box>
            <TextInput placeholder='Enter email here' label='Email' mb='xs' {...form.getInputProps('email')} />
            <Button size='lg' type='submit'>
              Sign in with SSO
            </Button>
          </Stack>
        </form>
      )}

      {loginState === LoginState.LOGGING_IN && (
        <div>Logging you in with the configured SSO provider, please wait...</div>
      )}

      {loginState === LoginState.LOGIN_SUCCESS && <div>SSO login success! Redirecting...</div>}

      {loginState === LoginState.NO_PROVIDER && (
        <Box mt='xl'>
          <Text c='red'>Your account is not configured to use SAML SSO. Please log in with Google or a password.</Text>
        </Box>
      )}
    </div>
  );
}
