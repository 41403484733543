import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FormikProps, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';

import Utils from 'utilities/Utils';
import { EmailAlreadyExistType } from 'utilities/enum-utils';
import { EValidationStatus } from 'registration-flow-v2/types';
import { PASSWORD_ERRORS, REGISTRATION_STEPS, SIGN_UP_STATUS } from '../constants';
import { IAccountInformationForm, IExistingDomainData } from '../interfaces';
import { Button, css, EyeClose, EyeOpen, Heading, InputField, Link, Text, TextField } from 'registration-flow-v2';
import AccountInformationAlert from '../components/AccountInformationAlert';
import { AccountInformationSchema } from 'utilities/form-utils';
import { IRootDispatch } from 'stores/rematch/root-store';
import RequestAccessModal from '../components/RequestAccessModal';
import * as styles from 'views/css/css-components/AccountInformationSection.css';
import { STRIPE_CONFIG } from 'variables/app-config';
import { loginPath } from 'utilities/navigation';

type AccountInformationFormProperties = {
  setStep: (value: REGISTRATION_STEPS) => void;
  setSignUpStatus: (value: string) => void;
  setAccountInformationFormValues: (values: IAccountInformationForm) => void;
};

const AccountInformationForm = ({
  setStep,
  setSignUpStatus,
  setAccountInformationFormValues,
}: AccountInformationFormProperties) => {
  const dispatch = useDispatch<IRootDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const [alertActionDisabled, setAlertActionDisabled] = useState<boolean>(false);
  const [emailAlreadyExistType, setEmailAlreadyExistType] = useState<string>(null);
  const [existingDomainData, setExistingDomainData] = useState<IExistingDomainData>(null);

  const formik: FormikProps<IAccountInformationForm> = useFormik<IAccountInformationForm>({
    initialValues: { firstName: '', lastName: '', email: '', password: '' },
    validationSchema: AccountInformationSchema,
    onSubmit: () => submitForm(),
  });

  const trialPeriodInDays = STRIPE_CONFIG.DEFAULT_TRIAL_PERIOD_IN_DAYS;
  const { values, isValid, dirty, touched, errors, handleSubmit, handleBlur, setFieldValue } = formik;

  const createUser = async () => {
    setAlertActionDisabled(true);
    try {
      await dispatch.authStore.doCreateNewPrimaryOwner(values);
      setAccountInformationFormValues(values);
      setSignUpStatus(SIGN_UP_STATUS.SUBMITTED);
      setStep(REGISTRATION_STEPS.VERIFY_EMAIL_OR_START_COMPANY);
    } catch (e) {
      if (e.code === 'auth/email-already-in-use') {
        const result: { isAlreadyLinked: boolean; isWaitingToCreateOrganisation: boolean } =
          await dispatch.authStore.doGetAlreadyExistingPortalUserType({ email: values.email });
        if (result.isWaitingToCreateOrganisation) {
          setEmailAlreadyExistType(EmailAlreadyExistType.WAITING_TO_CREATE_ORGANISATION);
        } else {
          setEmailAlreadyExistType(EmailAlreadyExistType.ALREADY_LINKED);
        }
      } else {
        notification.error({
          message: 'Oops! Something went wrong, please try again.',
          description: e.message,
        });
      }
    }
    setAlertActionDisabled(false);
  };

  const submitForm = async () => {
    setIsLoading(true);
    const result: {
      emailDomainExist: boolean;
      targetEmail: string | null;
      serviceProviderName: string | null;
      isNewUser: boolean;
      emailAlreadyExistType: EmailAlreadyExistType;
    } = await dispatch.authStore.doCheckExistingEmailDomain({ email: values.email });

    if (!result.isNewUser && result.emailAlreadyExistType) {
      setEmailAlreadyExistType(result.emailAlreadyExistType);
    } else if (result.emailDomainExist) {
      setExistingDomainData({
        targetEmail: result.targetEmail,
        serviceProviderName: result.serviceProviderName,
      });
    } else {
      await createUser();
    }
    setIsLoading(false);
  };

  const handleSendRequest = async (message = null) => {
    setIsSendingRequest(true);
    const { email, firstName, lastName } = values;
    try {
      if (!existingDomainData) {
        throw new Error('Missing data');
      }
      await dispatch.authStore.doRequestAccess({
        requesterFirstName: firstName,
        requesterLastName: lastName,
        requesterEmail: email,
        targetEmail: existingDomainData.targetEmail,
        serviceProviderName: existingDomainData.serviceProviderName,
        message,
      });
      setSignUpStatus(SIGN_UP_STATUS.SUBMITTED_WITH_REQUEST_ACCESS);
      setStep(REGISTRATION_STEPS.VERIFY_EMAIL_OR_START_COMPANY);
      setIsShowModal(false);
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong.',
      });
    }
    setIsSendingRequest(false);
  };

  const goToLogin = () => {
    window.open(loginPath(), '_self');
  };

  const goToLoginNewTab = () => {
    window.open(loginPath(), '_blank');
  };

  const showPasswordError = () => {
    if (!touched.password || !errors.password) return;

    if (!errors.password.includes('_')) {
      return [
        {
          status: EValidationStatus.Invalid,
          message: errors.password,
        },
      ];
    }

    return Object.keys(PASSWORD_ERRORS).map((errorKey) => ({
      status: errors.password.includes(errorKey) ? EValidationStatus.Invalid : EValidationStatus.Valid,
      message: PASSWORD_ERRORS[errorKey],
    }));
  };

  const sendEmail = async () => {
    setAlertActionDisabled(true);
    if (emailAlreadyExistType !== EmailAlreadyExistType.ALREADY_LINKED) {
      try {
        await dispatch.authStore.doResendNewPrimaryOwnerEmail({ email: values.email });
        setAccountInformationFormValues(values);
        setSignUpStatus(SIGN_UP_STATUS.SUBMITTED);
        setStep(REGISTRATION_STEPS.VERIFY_EMAIL_OR_START_COMPANY);
      } catch (e) {
        notification.error({
          message: 'Oops! Something went wrong.',
          description: e.meta.message,
        });
      }
    }
    setAlertActionDisabled(false);
  };

  const openModal = () => setIsShowModal(true);

  const closeModal = () => setIsShowModal(false);

  const showPassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  useEffect(() => {
    setStep(isValid && dirty ? REGISTRATION_STEPS.VALID_SIGN_UP : REGISTRATION_STEPS.START_SIGN_UP);
  }, [isValid, dirty]);

  return (
    <>
      <form onSubmit={handleSubmit} className={css(styles.form)()}>
        <Heading level={2} emphasis="light" marginBottom="$space300 !important">
          Start your GoodHuman for business trial today
        </Heading>

        <InputField label="Email" marginBottom="$space300">
          <TextField
            name="email"
            type="email"
            onChange={(value: string) => {
              setFieldValue('email', value);
              emailAlreadyExistType && setEmailAlreadyExistType(null);
              existingDomainData && setExistingDomainData(null);
            }}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Enter email here"
            validations={
              touched.email && errors.email && [{ status: EValidationStatus.Invalid, message: errors.email }]
            }
          />
        </InputField>
        {(emailAlreadyExistType || !Utils.isBlank(existingDomainData)) && (
          <AccountInformationAlert
            email={values.email}
            goToLogin={goToLogin}
            sendEmail={sendEmail}
            createUser={createUser}
            alertActionDisabled={alertActionDisabled}
            emailAlreadyExistType={emailAlreadyExistType}
            openRequestAccessModal={openModal}
            setExistingDomainData={setExistingDomainData}
            setEmailAlreadyExistType={setEmailAlreadyExistType}
          />
        )}
        <InputField label="First name" marginBottom="$space300">
          <TextField
            name="firstName"
            onChange={(value: string) => setFieldValue('firstName', value)}
            onBlur={handleBlur}
            value={values.firstName}
            placeholder="John"
            validations={
              touched.firstName &&
              errors.firstName && [{ status: EValidationStatus.Invalid, message: errors.firstName }]
            }
          />
        </InputField>
        <InputField label="Last name" marginBottom="$space300">
          <TextField
            name="lastName"
            onChange={(value: string) => setFieldValue('lastName', value)}
            onBlur={handleBlur}
            value={values.lastName}
            placeholder="Smith"
            validations={
              touched.lastName && errors.lastName && [{ status: EValidationStatus.Invalid, message: errors.lastName }]
            }
          />
        </InputField>
        <InputField label="Password" marginBottom="$space175">
          <TextField
            name="password"
            onChange={(value) => setFieldValue('password', value)}
            onBlur={handleBlur}
            value={values.password}
            label="Password"
            type={visiblePassword ? 'text' : 'password'}
            placeholder="Enter password here"
            validations={showPasswordError()}
            suffix={
              <span onClick={showPassword} className={css(styles.eyeIcon)()}>
                {visiblePassword ? <EyeClose color="$bodyDark1" /> : <EyeOpen color="$bodyDark1" />}
              </span>
            }
          />
        </InputField>

        <div className={css(styles.description)()}>
          Get started with a free 30-day free trial, you won’t be charged if you cancel before{' '}
          <Text emphasis="bold">{moment().add(trialPeriodInDays, 'days').format('DD MMMM YYYY')}</Text>.
        </div>
        <Button
          kind="accent"
          emphasis="filled"
          type="submit"
          width="$sizeFull"
          color="$white"
          backgroundColor="$ocean"
          marginTop="auto"
          size="large"
          borderWidth="$border0"
          isDisabled={!(isValid && dirty)}
          isLoading={isLoading}
        >
          Sign up
        </Button>
        <div className={css(styles.moreInfo)()}>
          Already have an account?
          <Link underline emphasis="bold" size="small" marginLeft="$space50" onClick={goToLoginNewTab}>
            Log in
          </Link>
        </div>
      </form>

      <RequestAccessModal
        isOpen={isShowModal}
        onClose={closeModal}
        onSendRequest={handleSendRequest}
        isLoading={isSendingRequest}
      />
    </>
  );
};

export default AccountInformationForm;
