import * as H from 'history';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { css } from 'registration-flow-v2';
import { IRootDispatch } from 'stores/rematch/root-store';
import FormWrapperSection from './components/FormWrapperSection';
import LeftSideBarSection from './components/LeftSideBarSection';
import * as commonStyles from './css/common-onboarding.css';
import ResetPasswordForm from './forms/ResetPasswordForm';
import { PASSWORD_RESET_STEPS, SCREEN_TYPE } from './constants';

type IProps = {
  history: H.History;
};

const ResetPasswordView = (props: IProps): JSX.Element => {
  const { history } = props;

  const dispatch = useDispatch<IRootDispatch>();

  const [email, setEmail] = useState<string>('');
  const [isErrored, setIsErrored] = useState<boolean>(false);

  const doCheckVerificationLink = async () => {
    const query = qs.parse(location.search);
    try {
      const response = await dispatch.authStore.doCheckActionToken({ code: query.oobCode });
      setEmail(response.data.email);
    } catch (e) {
      setIsErrored(true);
      console.error(e.message);
    }
  };

  useEffect(() => {
    doCheckVerificationLink();
  }, []);

  return (
    <div className={css(commonStyles.registrationContainer)()}>
      <LeftSideBarSection screenType={SCREEN_TYPE.PASSWORD_RESET} step={PASSWORD_RESET_STEPS.START_PASSWORD_RESET} />
      <FormWrapperSection>
        <ResetPasswordForm email={email} history={history} isErrored={isErrored} />
      </FormWrapperSection>
    </div>
  );
};

export default ResetPasswordView;
