import { notification } from 'antd';
import { FormikProps, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PaperPlane from 'assets/gif/paper-plane.gif';
import gmail from 'assets/social/gmail.svg';
import outlook from 'assets/social/outlook.svg';
import { Button, css, InputField, TextField } from 'registration-flow-v2';
import { EValidationStatus } from 'registration-flow-v2/types';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { VerifyAccountScheme } from 'utilities/form-utils';
import { ActionType, REGISTRATION_STEPS, SIGN_UP_STATUS, verifyAccountFormExtensions } from '../constants';
import * as styles from '../css/css-components/VerifyAccountSection.css';
import { IVerifyAccountForm } from '../interfaces';

interface IProps {
  email: string;
  setSignUpStatus?: (value: string) => void;
  setStep?: (value: REGISTRATION_STEPS) => void;
  actionType?: string;
  screenType?: string;
}

const VerifyAccountFormSection = ({ email, setSignUpStatus, setStep, actionType, screenType }: IProps) => {
  const dispatch = useDispatch<IRootDispatch>();
  const [isShowEmailInput, setIsShowEmailInput] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pendingPrimaryOwnerFirebaseUid = useSelector(
    (state: IRootState) => state.authStore.pendingPrimaryOwnerFirebaseUid,
  );

  const formik: FormikProps<IVerifyAccountForm> = useFormik<IVerifyAccountForm>({
    initialValues: { email: '' },
    validationSchema: VerifyAccountScheme,
    onSubmit: () => sendEmail(),
  });

  const { handleSubmit, handleBlur, values, isValid, dirty, setFieldValue, touched, errors } = formik;

  const sendEmail = async () => {
    setIsLoading(true);
    try {
      switch (actionType) {
        case ActionType.SEND_RESET_LINK: {
          await dispatch.authStore.doSendResetPasswordEmail({ email: values.email });
          break;
        }
        case ActionType.RE_ENTER_NEW_PRIMARY_OWNER: {
          await dispatch.authStore.doReEnterNewPrimaryOwnerEmail({
            email: values.email,
            pendingPrimaryOwnerFirebaseUid,
          });
          setSignUpStatus(SIGN_UP_STATUS.SUBMITTED);
          setStep(REGISTRATION_STEPS.VERIFY_EMAIL_OR_START_COMPANY);
          break;
        }
        default:
          break;
      }
      setIsShowEmailInput(false);
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong.',
        description: e.meta.message,
      });
    } finally {
      setIsShowEmailInput(false);
      setIsLoading(false);
    }
  };

  const goToEmailPage = () => {
    window.open('https://mail.google.com', '_blank');
  };

  const goToOutlookPage = () => {
    window.open('https://outlook.office.com', '_blank');
  };

  useEffect(() => {
    if (email) setFieldValue('email', email);
  }, [email]);

  return (
    <form onSubmit={handleSubmit} className={css(styles.form)()}>
      <div className={css(styles.title)()}>
        We have sent an email to <span className={css(styles.gmail)()}>{values.email}</span>{' '}
        {verifyAccountFormExtensions[screenType].shortSentence}.
      </div>

      <div onClick={goToEmailPage} className={css(styles.button)()}>
        <img src={gmail} />
        <span className={css(styles.text)()}>Open in Gmail</span>
      </div>

      <div onClick={goToOutlookPage} className={css(styles.button)()}>
        <img src={outlook} />
        <span className={css(styles.text)()}>Open in Outlook</span>
      </div>

      <div className={css(styles.moreInfo)()}>
        Didn't receive the email? Check your spam or{' '}
        <span onClick={() => setIsShowEmailInput(true)} className={css(styles.textReEnterEmail)()}>
          re-enter your email here
        </span>
        .
      </div>

      {isShowEmailInput ? (
        <>
          <InputField label="Email" marginBottom="$space200">
            <TextField
              name="email"
              onChange={(value) => setFieldValue('email', value)}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Enter email here"
              marginTop="$space75"
              validations={
                touched.email && errors.email && [{ status: EValidationStatus.Invalid, message: errors.email }]
              }
            />
          </InputField>
          <Button
            type="submit"
            width="$sizeFull"
            color="$white"
            borderWidth="$border0"
            emphasis="filled"
            isDisabled={!(isValid && dirty)}
            isLoading={isLoading}
            size="large"
          >
            Send
          </Button>
        </>
      ) : (
        <div className={css(styles.artWordWrapper)()}>
          <img src={PaperPlane} className={css(styles.paperPlane)()} />
        </div>
      )}
    </form>
  );
};

export default VerifyAccountFormSection;
