import { CSS } from 'registration-flow-v2/theme/index';

export const formContainer: CSS = {
  backgroundColor: '$white',
  zIndex: 3,

  '@initial': {
    height: '$sizeFull',
  },

  '@large': {
    width: '566px',
    borderRadius: '$large',
    overflow: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    marginTop: '$space600',
    marginBottom: '$space1000',
  },
  '@xlarge': {
    marginBottom: '$space600',
    marginRight: '85px',
  },
  '@xxxlarge': {
    marginRight: '$none',
    alignSelf: 'center',
  },
};

export const progressBar: CSS = {
  width: 'inherit',
  display: 'block',
  zIndex: 10,

  '@initial': {
    '& > div': {
      height: '$size100',
    },
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
  },
  '@large': {
    position: 'static',
    '& > div': {
      height: '$size125',
    },
  },
  '@xlarge': {
    display: 'none',
  },
};

export const formDetails: CSS = {
  width: '$sizeFull',
  height: '$sizeFull',
  padding: '$space250 $space125',

  '@initial': {
    padding: '$space500 $space200 51px',
  },

  '@medium': {
    padding: '$space500 123px 51px',
  },

  '@large': {
    padding: '$space500 51px $space400',
  },
};

export const smallLogo: CSS = {
  marginBottom: '$space400',

  '@xsmall': {
    display: 'block',
  },
  '@xlarge': {
    display: 'none',
  },
};
