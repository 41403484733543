import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { useLink } from '@react-aria/link';
import React, { forwardRef, RefObject } from 'react';
import { mergeProps } from '@react-aria/utils';
import type { AriaLinkProps } from '@react-types/link';
import type { PropsWithChildren } from 'react';

import * as styles from './link.css';
import { LinkVariants } from './link.css';
import type { HTMLProps, TextStyleProps } from '..';
import { LinkContext, LinkIconProvider, LinkProviderProps } from './link-provider';
import { compose, css, textStyleProps, useContextProps, useStyleProps } from '..';

export type LinkProps = PropsWithChildren<
  AriaLinkProps &
    TextStyleProps &
    LinkVariants &
    HTMLProps<HTMLAnchorElement> & {
      /**
       * Whether the click events should be disabled.
       */
      isDisabled?: boolean;
      /**
       * The href attribute specifies the URL of the page the link goes to.
       */
      href?: string;
      /**
       * Whether place link is opened. Default is blank tab
       */
      target?: '_blank' | '_self' | '_parent' | '_top' | string;
    }
>;

/**
 * @name
 * Link
 *
 * @description
 * The Link HTML element is an interactive element activated by
 * a user.
 *
 * @example
 * <Link
 *   underline
 *   key={kind}
 *   emphasis="bold"
 *   size="small"
 *   isDisabled
 *   href="/login"
 * />
 */

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  const contextProps = useContextProps<LinkProps & LinkProviderProps>(LinkContext, props);

  const {
    children,
    emphasis = 'regular',
    isDisabled,
    underline,
    kind = 'accent',
    size = 'medium',
    href,
    target = '_blank',
  } = contextProps;

  let { linkProps, isPressed } = useLink(props, ref as RefObject<HTMLAnchorElement>);

  const { styleProps } = useStyleProps(contextProps, textStyleProps);
  const { hoverProps, isHovered } = useHover({ isDisabled });
  const { focusProps, isFocusVisible } = useFocusRing(props);
  linkProps = mergeProps(linkProps, hoverProps, focusProps);

  return (
    <a
      {...linkProps}
      tabIndex={0}
      ref={ref}
      href={href}
      target={target}
      className={compose(
        css(styles.reset, styles.link),
        styles.variants({
          emphasis,
          kind,
          size,
          isDisabled,
          underline,
          isFocused: isFocusVisible,
          isHovered,
          isPressed,
        }),
        css(styleProps),
      )}
    >
      <LinkIconProvider size={size}>{children}</LinkIconProvider>
    </a>
  );
});
