import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const reset: CSS = {
  appearance: 'none',
  cursor: 'pointer',
  outline: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  touchAction: 'none',
  textDecoration: 'none',
};

export const link: CSS = {
  fontFamily: '$primary',
};

export const variants = css({
  color: '$$linkTextColor',
  fontSize: '$$linkFontSize',
  lineHeight: '$$linkLineHeight',
  fontWeight: '$$linkFontWeight',
  borderStyle: '$solid',
  borderColor: 'transparent',
  borderRadius: '$small',
  gap: '$space50',

  variants: {
    kind: {
      accent: {
        $$linkTextColor: '$colors-accent',
        $$linkTextColorHover: '$colors-accentHover',
        $$linkTextColorPressed: '$colors-accentPressed',
        $$linkBorderColorFocused: '$colors-accent',
        $$linkBackgroundColorFocused: '$colors-white',
      },
      success: {
        $$linkTextColor: '$colors-positive',
        $$linkTextColorHover: '$colors-positiveHover',
        $$linkTextColorPressed: '$colors-positivePressed',
        $$linkBorderColorFocused: '$colors-positive',
        $$linkBackgroundColorFocused: '$colors-white',
      },
      warning: {
        $$linkTextColor: '$colors-notice',
        $$linkTextColorHover: '$colors-noticeHover',
        $$linkTextColorPressed: '$colors-noticePressed',
        $$linkBorderColorFocused: '$colors-notice',
        $$linkBackgroundColorFocused: '$colors-white',
      },
      destructive: {
        $$linkTextColor: '$colors-critical',
        $$linkTextColorHover: '$colors-criticalHover',
        $$linkTextColorPressed: '$colors-criticalPressed',
        $$linkBorderColorFocused: '$colors-critical',
        $$linkBackgroundColorFocused: '$colors-white',
      },
      black: {
        $$linkTextColor: '$colors-black',
        $$linkTextColorHover: '$colors-neutralDark2',
        $$linkTextColorPressed: '$colors-neutralDark1',
        $$linkBorderColorFocused: '$colors-black',
        $$linkBackgroundColorFocused: '$colors-white',
      },
      white: {
        $$linkTextColor: '$colors-white',
        $$linkTextColorHover: '$colors-white',
        $$linkTextColorPressed: '$colors-white',
        $$linkBorderColorFocused: '$colors-white',
        $$linkBackgroundColorFocused: '$colors-black',
      },
    },
    emphasis: {
      bold: {
        $$linkFontWeight: '$fontWeights-bold',
      },
      semibold: {
        $$linkFontWeight: '$fontWeights-semibold',
      },
      regular: {
        $$linkFontWeight: '$fontWeights-normal',
      },
    },
    size: {
      small: {
        $$linkFontSize: '$fontSizes-small',
        $$linkLineHeight: '$lineHeights-small',
        borderWidth: '$border125',
      },
      medium: {
        $$linkFontSize: '$fontSizes-medium',
        $$linkLineHeight: '$lineHeights-medium',
        borderWidth: '$border150',
      },
      large: {
        $$linkFontSize: '$fontSizes-font200',
        $$linkLineHeight: '$lineHeights-large',
        borderWidth: '$border175',
      },
    },
    underline: {
      true: {
        textDecorationLine: 'underline !important',
        textDecorationColor: 'currentColor',
      },
    },
    isHovered: {
      true: {
        color: '$$linkTextColorHover !important',
      },
    },
    isDisabled: {
      true: {
        color: '$bodyDark1 !important',
        cursor: 'default !important',
        textDecorationLine: 'none !important',
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
      },
    },
    isPressed: {
      true: {
        color: '$$linkTextColorPressed !important',
      },
    },
    isFocused: {
      true: {
        backgroundColor: '$$linkBackgroundColorFocused',
        borderColor: '$$linkBorderColorFocused',
        padding: '$space0 $space50',
      },
    },
  },
  defaultVariants: {
    kind: 'accent',
    emphasis: 'regular',
  },
});

export type LinkVariants = Stitches.VariantProps<typeof variants>;
