import { filterDOMProps, mergeRefs } from '@react-aria/utils';
import type { PropsWithChildren } from 'react';
import React, { forwardRef, useRef } from 'react';
import type { BoxStyleProps, HTMLProps } from '..';
import { boxStyleProps, Button, compose, css, useContextProps, useStyleProps, Box } from '..';
import { Cross } from '../icon';
import type { AlertProviderProps } from './alert-provider';
import { AlertActionsProvider, AlertContext } from './alert-provider';
import type { AlertVariants } from './alert.css';
import * as styles from './alert.css';
import { AlertTitle } from './title';

export type AlertProps = PropsWithChildren<
  BoxStyleProps &
    AlertVariants &
    HTMLProps<HTMLDivElement> & {
      /**
       * Title content of alert
       */
      title: string | React.ReactNode;

      /**
       * Optional actions relative to alert
       * If Link components are included, they will have default style relative to alert's tone
       */
      actions?: React.ReactNode;

      /**
       * Whether the close button is show
       */
      closeable?: boolean;

      /**
       * Optional close handle
       */
      onClose?(): void;
    }
>;

/**
 * @name
 * Alert
 *
 * @example
 * <Alert kind="inline" tone="danger" emphasis="filled" onClose={() => setIsAlertOpen(false)}
 *  title="Alert title"
 *  actions={
 *    <>
 *      <Link>Link 1</Link>
 *      <Link>Link 2</Link>
 *    </>
 *  }>
 *   Alert body
 * </Alert>
 */
export const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  const contextProps = useContextProps<AlertProviderProps>(AlertContext, props);
  const {
    alertRef = null,
    kind = 'inline',
    emphasis = 'filled',
    tone = 'danger',
    closeable = true,
    middle,
    children,
    title,
    actions,
    boldTitle = true,
    onClose,
  } = contextProps;

  ref = mergeRefs(alertRef, ref) || useRef(null);

  const { styleProps, ...otherProps } = useStyleProps(contextProps, boxStyleProps);

  return (
    <div
      {...filterDOMProps(otherProps)}
      className={compose(
        css(styles.reset, styles.alert),
        styles.variants({
          middle,
          kind,
          emphasis,
          tone,
          missingActionFilledAlert: !actions && emphasis === 'outlined',
        }),
        css(styleProps),
      )}
      ref={ref}
    >
      <div className={compose(styles.contentVariants({ kind }))}>
        <AlertTitle tone={tone} kind={kind} middle={middle} boldTitle={boldTitle} emphasis={emphasis}>
          {title}
        </AlertTitle>
        {children && <Box marginTop={kind === 'inline' ? '$space100' : ''}>{children}</Box>}
        {actions && (
          <AlertActionsProvider tone={tone}>
            <div className={compose(css(styles.actions), styles.actionsVariants({ middle, kind, emphasis }))}>
              {actions}
            </div>
          </AlertActionsProvider>
        )}
      </div>

      {closeable && (
        <Button onPress={onClose} fitContent emphasis="quiet" color="$black" size="small" order="1">
          <Cross width="$size250" height="$size250" alignSelf="center" />
        </Button>
      )}
    </div>
  );
});
