import { message } from 'antd';
import { trpc } from './trpc';
import { loginPath, workspaceRedirectionUrl, pathWithWorkspaceRedirect } from 'utilities/navigation';
import { generateZendeskRedirectUrl } from './zendesk';
import { history } from 'utilities/history';

export const loginViaAuthService = async ({
  doSignOutUsers,
}: {
  doSignOutUsers: () => Promise<void>;
}): Promise<void> => {
  const serviceProvidersResult = await trpc.serviceProviders.list.query();
  const activeProvidersWithPortalAccess = serviceProvidersResult.providers
    .filter((provider) => !['BLOCKED', 'REMOVED'].includes(provider.serviceProviderStatus))
    .filter((provider) => provider.hasPortalAccess);

  const [singleProvider, ...moreProviders] = activeProvidersWithPortalAccess;
  if (moreProviders.length > 0) {
    history.push(pathWithWorkspaceRedirect(`/providers`));
    return;
  }

  if (singleProvider) {
    const loginResult = await trpc.auth.login.mutate({ serviceProviderId: singleProvider.serviceProviderId });
    const workspaceRedirectUrl = workspaceRedirectionUrl({
      token: loginResult.token,
      serviceProviderId: singleProvider.serviceProviderId,
    });
    window.location.assign(workspaceRedirectUrl);
  } else {
    await message.error(
      `Oops, it doesn't look like you have portal access for any service providers. If you think this is a mistake, please contact your administrator.`,
    );
    await doSignOutUsers();
    window.location.assign(loginPath());
    return;
  }
};

export const loginAndRedirectToZendesk = async ({
  zendeskRedirectUrl,
}: {
  zendeskRedirectUrl: string;
}): Promise<void> => {
  const serviceProvidersResult = await trpc.serviceProviders.list.query();
  const activeProviders = serviceProvidersResult.providers.filter(
    (provider) => !['BLOCKED', 'REMOVED'].includes(provider.serviceProviderStatus),
  );

  const redirectUrl = generateZendeskRedirectUrl({
    user: serviceProvidersResult.user,
    hasWorkerAppAccess: activeProviders.some((membership) => membership.hasWorkerAppAccess),
    hasPortalAccess: activeProviders.some((membership) => membership.hasPortalAccess),
    redirectUrl: zendeskRedirectUrl,
  });

  window.location.assign(redirectUrl);
};
