import React from 'react';
import FormWrapperSection from '../components/FormWrapperSection';
import * as commonStyles from '../css/common-onboarding.css';
import { css } from 'registration-flow-v2';
import { Heading, Text } from '@good/components';
import LeftSideBarSection from '../components/LeftSideBarSection';
import { SCREEN_TYPE, WORKSPACE_SIGN_IN_STEPS } from '../constants';
import { reactTrpc } from 'utilities/react-trpc';
import { Redirect } from 'react-router-dom';
import { ServiceProviderCardSkeleton } from './components/service-provider-card-skeleton';
import { ServiceProviderSelection } from './components/service-provider-selection';

export const ProviderSelectionView = (): JSX.Element => {
  const { data: serviceProvidersResult, error: serviceProviderError } = reactTrpc.serviceProviders.list.useQuery();
  const userName = serviceProvidersResult?.user.firstName;

  if (serviceProviderError?.data?.code === 'UNAUTHORIZED') return <Redirect to="/login" />;

  return (
    <div className={css(commonStyles.registrationContainer)()}>
      <LeftSideBarSection screenType={SCREEN_TYPE.WORKSPACE_SIGN_IN} step={WORKSPACE_SIGN_IN_STEPS.VALID_SIGN_IN} />

      <FormWrapperSection>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-2">
            <Heading size="lg">Welcome{userName ? ` ${userName}` : ''},</Heading>
            <Text size="xl" className="font-medium">
              Which provider are you signing into today?
            </Text>
          </div>

          {serviceProvidersResult ? (
            <ServiceProviderSelection
              providers={serviceProvidersResult.providers.filter((provider) => provider.hasPortalAccess)}
            />
          ) : serviceProviderError ? (
            <Text>An error has occurred</Text>
          ) : (
            <div className="flex flex-col gap-4">
              <ServiceProviderCardSkeleton />
              <ServiceProviderCardSkeleton />
              <span className="sr-only">Loading business details...</span>
            </div>
          )}
        </div>
      </FormWrapperSection>
    </div>
  );
};
