import React, { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { css } from 'registration-flow-v2';
import * as commonStyles from 'views/css/common-onboarding.css';
import FormWrapperSection from './components/FormWrapperSection';
import LeftSideBarSection from './components/LeftSideBarSection';
import ScreenThankRegister from './components/ScreenThankRegister';
import { INVITED_STEPS, SCREEN_TYPE } from './constants';
import RegisterForm from './forms/RegisterForm';

const RegisterView = (): JSX.Element => {
  const [step, setStep] = useState<INVITED_STEPS>(INVITED_STEPS.START_INVITED);
  const [isSuccessScreenVisible, setIsSuccessScreenVisible] = useState<boolean>(false);
  const [isCircleFullScreen, setIsCircleFullScreen] = useState<boolean>(false);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(true);

  return (
    <div className={css(commonStyles.registrationContainer)()}>
      <LeftSideBarSection
        screenType={SCREEN_TYPE.INVITED_MEMBER}
        step={isCircleFullScreen ? INVITED_STEPS.VALID_INVITED : INVITED_STEPS.START_INVITED}
      />

      <FormWrapperSection screenType={SCREEN_TYPE.INVITED_MEMBER} step={step} isLoadingForm={isLoadingForm}>
        <RegisterForm
          visibleSuccessScreen={() => setIsSuccessScreenVisible(true)}
          setStep={setStep}
          setIsCircleFullScreen={setIsCircleFullScreen}
          setIsLoadingForm={setIsLoadingForm}
        />
      </FormWrapperSection>
      {isSuccessScreenVisible && (isMobile || isTablet) && <ScreenThankRegister />}
    </div>
  );
};

export default RegisterView;
