import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import React, { useState } from 'react';
import { reactTrpc } from '../utilities/react-trpc';
import { getAuth } from 'firebase/auth';
import { APP_CONFIG } from 'variables/app-config';

export function AuthServiceTrpcProvider({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    reactTrpc.createClient({
      links: [
        httpBatchLink({
          url: `${APP_CONFIG.AUTH_SERVICE_ORIGIN}/trpc`,
          async headers() {
            const currentUser = getAuth().currentUser;
            const idToken = await currentUser?.getIdToken();

            return {
              authorization: idToken ? `Bearer ${idToken}` : undefined,
            };
          },
        }),
      ],
    }),
  );
  return (
    <reactTrpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </reactTrpc.Provider>
  );
}
