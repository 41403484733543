import { CSS } from 'registration-flow-v2';

export const form: CSS = {
  display: 'flex',
  flexDirection: 'column',
};

export const eyeIcon: CSS = {
  cursor: 'pointer',
};
