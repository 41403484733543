import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { notification } from 'antd';
import { signInWithCustomToken } from 'firebase/auth';
import * as H from 'history';
import { IRegistrationAccountInfo, IPendingPrimaryOwner } from 'interfaces/auth-store-types';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, css, Heading } from 'registration-flow-v2';
import firebaseApp from 'stores/firebase-app';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { EmailAlreadyExistType } from 'utilities/enum-utils';
import FormWrapperSection from './components/FormWrapperSection';
import LeftSideBarSection from './components/LeftSideBarSection';
import LoginModal from './components/LoginModal';
import { SubscriptionDetails } from './components/SubscriptionDetail';
import { ORGANISATION_STEPS, REGISTRATION_STEPS, SCREEN_TYPE } from './constants';
import * as commonStyles from './css/common-onboarding.css';
import CompanyDetailsForm from './forms/CompanyDetailsForm';
import { ICompanyDetailForm } from './interfaces';
import ResultView from './ResultView';

import { isUSEnvironment, STRIPE_CONFIG } from 'variables/app-config';
import { Flag } from 'common-components/utils/launch-darkly/flag';
import ErrorStateMessage from './components/ErrorStateMessage';
import { workspaceRedirectionUrl } from 'utilities/navigation';
import { CountryCode } from 'variables/locales';

type IProps = {
  history: H.History;
};

type ICreatingAccountInfo = {
  uid: string;
  pendingPrimaryOwner: IPendingPrimaryOwner | null;
};

type IOrganisationState = {
  loginErrorType: EmailAlreadyExistType | null;
  isFinalStepMobile: boolean;
  abnNotValidError?: boolean;
  isValidatingABN: boolean;
  isSubmitting: boolean;
  isLoggingIn: boolean;
  visibleLoginModal: boolean;
};

type IInfoData = {
  companyName: string;
  abn: string;
  ndisNumber: string;
  workPhone: string;
  companySize: string;
  countryCode: CountryCode;
};

const stripePromise = loadStripe(STRIPE_CONFIG.STRIPE_PUBLISHABLE_KEY);

const OrganisationView = ({ history }: IProps): JSX.Element => {
  const dispatch = useDispatch<IRootDispatch>();
  const location = useLocation();
  const setupIntent = useSelector((state: IRootState) => state.stripeStore.setupIntent);

  const [registrationStep, setRegistrationStep] = useState<REGISTRATION_STEPS>(
    REGISTRATION_STEPS.VERIFY_EMAIL_OR_START_COMPANY,
  );
  const [currentStep, setCurrentStep] = useState<string>(ORGANISATION_STEPS.COMPANY_DETAILS);
  const [isLoadingCompanyDetails, setIsLoadingCompanyDetails] = useState<boolean>(false);
  const [organisationState, setOrganisationState] = useState<IOrganisationState>({
    loginErrorType: null,
    isFinalStepMobile: false,
    abnNotValidError: false,
    isValidatingABN: false,
    isSubmitting: false,
    isLoggingIn: false,
    visibleLoginModal: false,
  });
  const [creatingAccountInfo, setCreatingAccountInfo] = useState<ICreatingAccountInfo>({
    uid: '',
    pendingPrimaryOwner: null,
  });
  const [infoData, setInfoData] = useState<IInfoData>({
    companyName: '',
    abn: '',
    ndisNumber: '',
    workPhone: '',
    companySize: '',
    countryCode: isUSEnvironment() ? 'US' : 'AU',
  });

  const handleLoginSuccess = async (result: IRegistrationAccountInfo) => {
    const { uid, userType } = result;

    if (userType.isWaitingToCreateOrganisation && uid) {
      //Retrieve Pending primary user data
      const pendingPrimaryOwner = await dispatch.authStore.doGetPendingPrimaryOwner({ firebaseUid: uid });
      // creating initial setup intent
      // the setup intent object will be used to store customer payment method for future billings
      await dispatch.stripeStore.doCreateSetupIntent({
        data: { payment_method_types: ['card'], customer: pendingPrimaryOwner.stripeCustomerId },
      });

      setCreatingAccountInfo({
        uid,
        pendingPrimaryOwner,
      });

      setOrganisationState((prev) => ({
        ...prev,
        loginErrorType: null,
        visibleLoginModal: false,
      }));
      notification.success({ message: 'Logged in!' });
    } else if (userType.isAlreadyLinked) {
      setOrganisationState({
        ...organisationState,
        loginErrorType: EmailAlreadyExistType.ALREADY_LINKED,
        isFinalStepMobile: isMobile, // Navigate directly to final mobile view
        ...(isMobile && { visibleLoginModal: false }),
        // companyName: userType?.companyName,
      });
    } else {
      setOrganisationState({
        ...organisationState,
        loginErrorType: EmailAlreadyExistType.WAITING_TO_CREATE_ORGANISATION,
      });
    }
  };

  const signIn = async (validToken: boolean, currentUser) => {
    const data = new URLSearchParams(location.search);
    const auth = firebaseApp.auth;

    if (validToken && !currentUser) {
      setOrganisationState((prev) => ({ ...prev, isLoggingIn: true }));
      await signInWithCustomToken(auth, data.get('token'))
        .then(async () => {
          //Retrieve Pending primary user data
          const pendingPrimaryOwner = await dispatch.authStore.doGetPendingPrimaryOwner({
            firebaseUid: auth.currentUser.uid,
          });

          // creating initial setup intent
          // the setup intent object will be used to store customer payment method for future billings
          await dispatch.stripeStore.doCreateSetupIntent({
            data: { payment_method_types: ['card'], customer: pendingPrimaryOwner.stripeCustomerId },
          });

          setCreatingAccountInfo({
            uid: auth.currentUser.uid,
            pendingPrimaryOwner: pendingPrimaryOwner,
          });
        })
        .catch((e) => {
          console.log(e);
          setOrganisationState((prev) => ({ ...prev, visibleLoginModal: true }));
        })
        .finally(() => {
          setOrganisationState((prev) => ({ ...prev, isLoggingIn: false }));
        });
    }
    if (!validToken) setOrganisationState({ ...organisationState, visibleLoginModal: true });
  };

  const onCreateOrganisationSuccess = ({ token, serviceProviderId }: { token: string; serviceProviderId: string }) => {
    // prevent redirect to Goodhuman Workspace on Mobile
    if (isMobile) {
      setOrganisationState({
        ...organisationState,
        isSubmitting: false,
        isFinalStepMobile: true,
      });
      return;
    }
    // redirect the user straight to Workspace
    window.location.assign(workspaceRedirectionUrl({ token, serviceProviderId }));
  };

  const checkABNValidity = async (abn: string) => {
    if (infoData.countryCode !== 'AU') {
      return true;
    }

    try {
      return await dispatch.authStore.doCheckABNNumber({ abn });
    } catch (e) {
      // To avoid user being blocked by an error with ABN lookup, we let them go further even if the ABN is not verified
      return true;
    }
  };

  const handleSubmitCompanyDetails = async (values: ICompanyDetailForm) => {
    setIsLoadingCompanyDetails(true);
    const { uid } = creatingAccountInfo;
    const { abn } = values;
    let isFormValid = true;

    if (!uid) {
      notification.error({ message: 'Please login to continue' });
      setOrganisationState((prev) => ({ ...prev, visibleLoginModal: true }));
      isFormValid = false;
    }

    if (currentStep === ORGANISATION_STEPS.COMPANY_DETAILS && abn) {
      setOrganisationState((prev) => ({ ...prev, isValidatingABN: true, abnNotValidError: false }));
      const isABNValid = await checkABNValidity(abn);
      if (!isABNValid) {
        setOrganisationState((prev) => ({ ...prev, abnNotValidError: true }));
        isFormValid = false;
      }
    }
    setOrganisationState((prev) => ({ ...prev, isValidatingABN: false }));
    if (isFormValid) {
      setInfoData((prev) => {
        if (prev.countryCode !== 'AU') {
          return { ...prev, ...values, abn: '', ndisNumber: '' };
        }

        return { ...prev, ...values };
      });
      setCurrentStep(ORGANISATION_STEPS.PAYMENT);
      setRegistrationStep(REGISTRATION_STEPS.START_PAYMENTS);
    }
    setIsLoadingCompanyDetails(false);
  };

  useEffect(() => {
    const data = new URLSearchParams(location.search);
    const auth = firebaseApp.auth;
    const validToken = !!data.get('token');
    signIn(validToken, auth.currentUser);
  }, []);

  const options = {
    clientSecret: setupIntent ? setupIntent.client_secret : null,
  };

  const onChangeCountryCode = useCallback((countryCode: CountryCode) => {
    setInfoData((prev) => ({ ...prev, countryCode }));
  }, []);

  return (
    <div className={css(commonStyles.registrationContainer)()}>
      <LeftSideBarSection screenType={SCREEN_TYPE.REGISTRATION_FLOW} step={registrationStep} />
      <FormWrapperSection hasProgressBar screenType={SCREEN_TYPE.REGISTRATION_FLOW} step={registrationStep}>
        <Flag
          flag='selfServiceRegistration'
          off={<ErrorStateMessage />}
          on={
            <>
              {currentStep === ORGANISATION_STEPS.COMPANY_DETAILS && (
                <CompanyDetailsForm
                  isInvalidABNNumber={organisationState.abnNotValidError}
                  onValidStateChange={(isValid) =>
                    setRegistrationStep(
                      isValid
                        ? REGISTRATION_STEPS.VALID_COMPANY_DETAILS
                        : REGISTRATION_STEPS.VERIFY_EMAIL_OR_START_COMPANY,
                    )
                  }
                  onChangeABNErrorState={() =>
                    setOrganisationState((prevState) => ({ ...prevState, abnNotValidError: undefined }))
                  }
                  onNextStep={handleSubmitCompanyDetails}
                  isLoading={isLoadingCompanyDetails || organisationState.isLoggingIn}
                  countryCode={infoData.countryCode}
                  onChangeCountryCode={onChangeCountryCode}
                />
              )}
              {currentStep === ORGANISATION_STEPS.PAYMENT && (
                <Box display='flex' flexDirection='column'>
                  <Heading level='2' marginBottom='$space300' marginTop='$space0'>
                    Enter your payment details to start your 30-day free trial
                  </Heading>
                  <Elements stripe={stripePromise} options={options}>
                    <SubscriptionDetails
                      pendingPrimaryOwner={creatingAccountInfo.pendingPrimaryOwner}
                      companyName={infoData.companyName}
                      workPhone={infoData.workPhone}
                      abn={infoData.abn}
                      ndisNumber={infoData.ndisNumber}
                      companySize={infoData.companySize}
                      onValidStateChange={(isValid) =>
                        setRegistrationStep(
                          isValid ? REGISTRATION_STEPS.VALID_PAYMENTS : REGISTRATION_STEPS.START_PAYMENTS,
                        )
                      }
                      setIsSubmitting={(isSubmitting) => setOrganisationState((prev) => ({ ...prev, isSubmitting }))}
                      onCreateOrganisationSuccess={onCreateOrganisationSuccess}
                      countryCode={infoData.countryCode}
                    />
                  </Elements>
                </Box>
              )}
            </>
          }
        />
      </FormWrapperSection>

      <LoginModal
        visible={
          organisationState.visibleLoginModal ||
          organisationState.loginErrorType === EmailAlreadyExistType.WAITING_TO_CREATE_ORGANISATION
        }
        history={history}
        loginErrorType={organisationState.loginErrorType}
        onChangeLoginErrorType={(value) => setOrganisationState({ ...organisationState, loginErrorType: value })}
        onClose={() => setOrganisationState({ ...organisationState, visibleLoginModal: false })}
        onSubmitSuccess={handleLoginSuccess}
      />

      {(organisationState.isSubmitting || organisationState.isFinalStepMobile) && (
        <ResultView
          isSubmitting={organisationState.isSubmitting}
          companyName={infoData.companyName}
          customerName={creatingAccountInfo.pendingPrimaryOwner?.firstName}
        />
      )}
    </div>
  );
};

export default OrganisationView;
