import { notification } from 'antd';
import { FormikProps, useFormik } from 'formik';
import * as H from 'history';
import qs from 'query-string';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  css,
  EyeClose,
  EyeOpen,
  Heading,
  InputField,
  Link,
  Text,
  TextField,
} from 'registration-flow-v2';
import { EValidationStatus } from 'registration-flow-v2/types';
import { IRootDispatch } from 'stores/rematch/root-store';
import { ResetPasswordScheme } from 'utilities/form-utils';
import { loginPath } from 'utilities/navigation';
import { PASSWORD_ERRORS } from '../constants';
import { IResetPasswordForm } from '../interfaces';

type ResetPasswordFormProperties = {
  history?: H.History;
  isErrored: boolean;
  email: string;
};

const ResetPasswordForm = ({ history, isErrored, email }: ResetPasswordFormProperties) => {
  const dispatch = useDispatch<IRootDispatch>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState<boolean>(false);

  const formik: FormikProps<IResetPasswordForm> = useFormik<IResetPasswordForm>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: ResetPasswordScheme,
    onSubmit: () => onResetPassword(),
  });

  const { errors, values, touched, dirty, isValid, setFieldValue, handleBlur } = formik;

  const showPasswordError = () => {
    if (!touched.password || !errors.password) return;

    if (!errors.password.includes('_')) {
      return [
        {
          status: EValidationStatus.Invalid,
          message: errors.password,
        },
      ];
    }

    return Object.keys(PASSWORD_ERRORS).map((errorKey) => ({
      status: errors.password.includes(errorKey) ? EValidationStatus.Invalid : EValidationStatus.Valid,
      message: PASSWORD_ERRORS[errorKey],
    }));
  };

  const onResetPassword = async () => {
    setIsLoading(true);
    try {
      const query = qs.parse(location.search);
      await dispatch.authStore.doSetNewPassword({ code: query.oobCode, password: values.password });
      notification.success({
        message: 'Reset password success!',
        description: 'Redirecting to workspace ...',
      });
      await dispatch.authStore.doSignIn({ login: email, password: values.password });
    } catch (error) {
      notification.error({ message: 'Oops! Something went wrong.', description: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading level={2} emphasis="regular">
        Account recovery
      </Heading>

      <Box marginTop="$space100">
        <Text size="medium" color="$black">
          Enter your new password so we can get you back in your GoodHuman workspace!
        </Text>
      </Box>

      <InputField label="New password" marginTop="$space300" marginBottom="$space300">
        <TextField
          name="password"
          onChange={(value) => setFieldValue('password', value)}
          onBlur={handleBlur}
          value={values.password}
          label="Password"
          type={visiblePassword ? 'text' : 'password'}
          placeholder="Enter password here"
          validations={showPasswordError()}
          suffix={
            <span onClick={() => setVisiblePassword(!visiblePassword)} className={css({ cursor: 'pointer' })()}>
              {visiblePassword ? <EyeClose color="$bodyDark1" /> : <EyeOpen color="$bodyDark1" />}
            </span>
          }
        />
      </InputField>

      <InputField label="Confirm new password" marginBottom="$space300">
        <TextField
          name="confirmPassword"
          onChange={(value) => setFieldValue('confirmPassword', value)}
          onBlur={handleBlur}
          value={values.confirmPassword}
          label="Confirm new password"
          type={visibleConfirmPassword ? 'text' : 'password'}
          placeholder="Confirm password"
          validations={
            touched.confirmPassword &&
            errors.confirmPassword && [{ status: EValidationStatus.Invalid, message: errors.confirmPassword }]
          }
          suffix={
            <span
              onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}
              className={css({ cursor: 'pointer' })()}
            >
              {visibleConfirmPassword ? <EyeClose color="$bodyDark1" /> : <EyeOpen color="$bodyDark1" />}
            </span>
          }
        />
      </InputField>

      {isErrored && (
        <Box marginTop="$space150">
          <Alert
            title="Expired link"
            emphasis="filled"
            tone="danger"
            closeable={false}
            actions={<Link onClick={() => history.push(loginPath())}>Sign in</Link>}
          >
            Oops! This link has been expired. Try resetting your password again.
          </Alert>
        </Box>
      )}

      <Button
        kind="accent"
        emphasis="filled"
        type="submit"
        width="$sizeFull"
        color="$white"
        backgroundColor="$ocean"
        marginTop="$large"
        size="large"
        borderWidth="$border0"
        isDisabled={!(isValid && dirty) || isErrored}
        isLoading={isLoading}
      >
        Reset password
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
