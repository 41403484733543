import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import { IconContext } from './icon-provider';
import { useContextProps } from '../utils/context-props';
import { css, compose, iconStyleProps, useStyleProps } from '../theme';

import { iconVariants } from './icon.css';
import type { IconProps } from './icon-props';

export const Icon = forwardRef<SVGSVGElement, IconProps>(function Icon(props, ref) {
  const { children, viewBox = '0 0 256 256', size, ...contextProps } = useContextProps(IconContext, props);
  const { styleProps, ...otherProps } = useStyleProps(contextProps, iconStyleProps);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='transparent'
      viewBox={viewBox}
      {...filterDOMProps(otherProps)}
      className={compose(iconVariants({ size }), css(styleProps))}
      ref={ref}
    >
      {children}
    </svg>
  );
});
