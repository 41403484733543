import React, { forwardRef } from 'react';

import { Icon } from '../icon';
import type { IconProps } from '../icon-props';

export const TooltipIcon = forwardRef<SVGSVGElement, IconProps>(function Circle(props, ref) {
  return (
    <Icon {...props} viewBox='0 0 16 16' ref={ref}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5ZM1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8ZM8.66667 10.6667C8.66667 11.0349 8.36819 11.3333 8 11.3333C7.63181 11.3333 7.33333 11.0349 7.33333 10.6667C7.33333 10.2985 7.63181 10 8 10C8.36819 10 8.66667 10.2985 8.66667 10.6667ZM7.16667 6.66667C7.16667 6.20643 7.53976 5.83333 8 5.83333C8.46024 5.83333 8.83333 6.20643 8.83333 6.66667V6.74755C8.83333 6.99001 8.73701 7.22255 8.56557 7.39399L7.64645 8.31311C7.45118 8.50838 7.45118 8.82496 7.64645 9.02022C7.84171 9.21548 8.15829 9.21548 8.35355 9.02022L9.27267 8.1011C9.63166 7.74212 9.83333 7.25523 9.83333 6.74755V6.66667C9.83333 5.65414 9.01252 4.83333 8 4.83333C6.98748 4.83333 6.16667 5.65414 6.16667 6.66667V7C6.16667 7.27614 6.39052 7.5 6.66667 7.5C6.94281 7.5 7.16667 7.27614 7.16667 7V6.66667Z'
        fill='currentColor'
      />
    </Icon>
  );
});
