import { notification } from 'antd';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Heading, InputField, Text, TextField } from 'registration-flow-v2';
import { EValidationStatus } from 'registration-flow-v2/types';
import { IRootDispatch } from 'stores/rematch/root-store';
import * as Yup from 'yup';

interface IProps {
  onVisibleVerifyAccountForm?: (email: string) => void;
}

const ForgotPasswordForm = (props: IProps) => {
  const { onVisibleVerifyAccountForm } = props;

  const dispatch = useDispatch<IRootDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('Please enter an email address')
        .email('This email doesn’t look quite right, please check for any typos.'),
    }),
    onSubmit: () => onSendRestLink(),
  });

  const { errors, values, touched, dirty, isValid, setFieldValue, handleBlur } = formik;

  const onSendRestLink = async () => {
    setIsLoading(true);
    try {
      await dispatch.authStore.doSendResetPasswordEmail({ email: values.email });
      notification.success({
        message: 'Reset link sent!',
        description: 'Please check your email inbox and follow the instructions to reset your password.',
      });
      onVisibleVerifyAccountForm(values.email);
    } catch (error) {
      notification.error({
        message: 'Oops! Something went wrong.',
        description: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading level={2} emphasis="regular" marginBottom="$space100">
        Account recovery
      </Heading>

      <Text size="medium" color="$black">
        Enter your email and we'll send you a link to reset your password.
      </Text>

      <InputField label="Email" marginTop="$space300" marginBottom="$space125">
        <TextField
          name="email"
          type="email"
          placeholder="Enter email here"
          value={values.email}
          validations={touched.email && errors.email && [{ message: errors.email, status: EValidationStatus.Invalid }]}
          onBlur={handleBlur}
          onChange={(value: string) => setFieldValue('email', value)}
        />
      </InputField>

      <Button
        kind="accent"
        emphasis="filled"
        type="submit"
        width="$sizeFull"
        color="$white"
        backgroundColor="$ocean"
        marginTop="$large"
        size="large"
        borderWidth="$border0"
        isDisabled={!(isValid && dirty)}
        isLoading={isLoading}
      >
        Send reset link
      </Button>
    </form>
  );
};

export default ForgotPasswordForm;
