import { CSS } from 'registration-flow-v2/theme/index';

export const footer: CSS = {
  display: 'flex',
  gap: '$space250',
  alignItems: 'center',
  width: '100%',
  position: 'absolute',
  '& a': {
    zIndex: 2,
    transform: 'translateZ(1px)',
  },
  '@initial': {
    justifyContent: 'center',
    bottom: '$space150',
  },
  '@large': {
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '$space300',
  },
  '@xlarge': {
    justifyContent: 'flex-start',
    marginLeft: '$space700',
    bottom: '$space600',
  },
  '@xxxlarge': {
    marginLeft: '$space0',
  },
};
