import React from 'react';
import { Icon } from '../icon';
import type { IconProps } from '../icon-props';

export function Notice(props: IconProps) {
  return (
    <Icon {...props}>
      <path fill='none' d='M0 0h256v256H0z' />
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={16}
        d='M128 104v40M114.2 40l-88 152A16 16 0 0 0 40 216h176a16 16 0 0 0 13.8-24l-88-152a15.9 15.9 0 0 0-27.6 0Z'
      />
      <circle cx={128} cy={180} r={12} fill='currentColor' />
    </Icon>
  );
}
