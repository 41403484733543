import React, { CSSProperties, PureComponent } from 'react';
import { Title } from 'common-components/typography';
import { Dialog } from '@blueprintjs/core';
import { IconButton } from '../buttons';

interface IActionModalProps {
  isOpen: boolean; // indicates whether Modal is open or not
  onClose?: any; // function to handle close events
  showCloseButton?: boolean; // indicates whether to render the close button at the top right
  title?: React.ReactNode;
  showHeader?: boolean; // Show header (title + close button) section = yes / no
  verticalAlignment?: 'highest' | 'high' | 'center' | 'low' | 'lowest' | string; // vertical position of the modal
  width?: 'small' | 'medium' | 'large' | 'x-large' | 'x2-large' | 'full' | number | string; // width of the modal. can be a number or a predefined value
  canCloseOutside?: boolean; // can click outside to close modal or not
  className?: string;
  children?: any;
  minHeight?: string;
}

interface IActionModalState {}

class ActionModal extends PureComponent<IActionModalProps, IActionModalState> {
  baseClassName = 'bg-white padding-none margin-none rounded bordered-none ';

  // Base style overrides. Can't use className for the following for some reason.
  baseStyle = { width: '100%', margin: '0', paddingBottom: '0', position: 'relative', backgroundColor: 'white' };

  // width values mapping
  widthValues = {
    small: '480px',
    medium: '640px',
    large: '720px',
    'x-large': '880px',
    'x2-large': '1024px',
    'x3-large': '1152px',
    full: '100%',
  };

  // top offset mapping
  topOffsetValues = { highest: '10vh', high: '26vh', low: '48vh', lowest: '57vh' }; // center: '0vh'; // no need

  render() {
    const {
      isOpen = false,
      onClose,
      title,
      showCloseButton = true,
      showHeader = true,
      className,
      width = 'large',
      canCloseOutside = true,
      verticalAlignment = 'center',
      children,
      minHeight,
    } = this.props;

    // Append passed in className with the base classes
    const finalClassName = `${this.baseClassName} ${className}`;

    let targetStyle = { ...this.baseStyle };

    // width
    if (typeof width === 'number') {
      targetStyle['maxWidth'] = `${width}px`;
    } else {
      targetStyle['maxWidth'] = this.widthValues[width];
    }

    if (verticalAlignment !== 'center') {
      targetStyle.position = 'fixed';
      targetStyle['top'] = this.topOffsetValues[verticalAlignment];
    }

    if (minHeight) {
      targetStyle['minHeight'] = minHeight;
    }

    // cast it as CSSProperties; for some reason Dialog doesn't accept plain objects
    const finalStyle = { ...targetStyle } as CSSProperties;

    return (
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        className={finalClassName}
        style={finalStyle}
        canEscapeKeyClose={canCloseOutside}
        canOutsideClickClose={canCloseOutside}
      >
        <div className="p-large">
          {showHeader && <ActionModalHeader title={title} showCloseButton={showCloseButton} onClose={onClose} />}
          <div className="pt-small">{children}</div>
        </div>
      </Dialog>
    );
  }
}

interface ModalHeaderProps {
  title?: React.ReactNode;
  showCloseButton?: boolean;
  onClose?: any;
  children?: any;
}

interface ModalHeaderState {}

// ModalHeader is an internal class.
class ActionModalHeader extends PureComponent<ModalHeaderProps, ModalHeaderState> {
  render() {
    const { title, showCloseButton, onClose } = this.props;

    return (
      <div className="flex-row justify-between align-center">
        <Title level={4} className="mv-none">
          {title}
        </Title>

        {showCloseButton && (
          <IconButton
            icon={'close'}
            shape="circle"
            color="white"
            size="large"
            iconColor="secondary"
            onClick={onClose}
            className="hover-bg-secondary"
          />
        )}
      </div>
    );
  }
}

interface ModalFooterProps {
  className?: string;
  align?: 'left' | 'center' | 'right'; // alignment for the footer container
  children: any;
}

interface ModalFooterState {}

class ActionModalFooter extends PureComponent<ModalFooterProps, ModalFooterState> {
  render() {
    const { children, className, align = 'right' } = this.props;

    const alignClass = 'text-align-' + align;

    return <div className={`pt-medium ${alignClass} ${className}`}>{children}</div>;
  }
}

export default ActionModal;

export { ActionModalFooter };
