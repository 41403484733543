import React from 'react';

import ellipse from 'assets/ellipse.svg';
import doggo from 'assets/gif/doggo.gif';
import WomanComputer from 'assets/woman-computer.svg';
import { css, Text } from 'registration-flow-v2';
import * as commonStyles from 'views/css/common-onboarding.css';
import * as styles from './css/css-components/ResultSection.css';

type ResultViewProperties = {
  customerName?: string;
  companyName?: string;
  isSubmitting?: boolean;
};

const ResultView = ({ customerName, companyName, isSubmitting }: ResultViewProperties): JSX.Element => {
  return (
    <div className={css(commonStyles.resultViewContainer)()}>
      <div className={css(commonStyles.content)()}>
        <div className={css(commonStyles.groupImage)()}>
          <img src={ellipse} className={css(commonStyles.ellipse)()} />
          <img
            src={doggo}
            className={css(commonStyles.imageStatus, commonStyles.imageGif, isSubmitting ? styles.show : styles.hide)()}
          />
          <img
            src={WomanComputer}
            className={css(
              commonStyles.imageStatus,
              commonStyles.womanComputer,
              !isSubmitting ? styles.show : styles.hide,
            )()}
          />
        </div>
        <div className={css(commonStyles.textResultView)()}>
          {isSubmitting ? (
            'Sit tight for one moment while we create your GoodHuman workspace.'
          ) : (
            <>
              Thanks{' '}
              <Text emphasis="bold" fontSize="inherit !important">
                {customerName}!
              </Text>{' '}
              An account has been created for
              <Text emphasis="bold" fontSize="inherit !important">
                {' '}
                {companyName}
              </Text>
              . For the best experience, log in to your new workspace on desktop to get started.
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultView;
