import * as H from 'history';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { css } from 'registration-flow-v2';
import { dispatch } from 'stores/rematch/root-store';
import { loginPath } from 'utilities/navigation';
import FormWrapperSection from './components/FormWrapperSection';
import LeftSideBarSection from './components/LeftSideBarSection';
import { ActionType, SCREEN_TYPE, SIGN_IN_DOMAINS, WorkspaceSignInForm, WORKSPACE_SIGN_IN_STEPS } from './constants';
import * as commonStyles from './css/common-onboarding.css';
import ForgotPasswordForm from './forms/ForgotPasswordForm';
import SignInForm from './forms/SignInForm';
import VerifyAccountFormSection from './forms/VerifyAccountForm';
import { VerifySamlEmailForm } from './verify-saml-email-view';

const SignInView = ({ history }: { history?: H.History }): JSX.Element => {
  const [emailEntered, setEmailEntered] = useState<string>('');
  const [formDisplayed, setFormDisplayed] = useState<string>(WorkspaceSignInForm.SIGN_IN);
  const [isCircleFullScreen, setIsCircleFullScreen] = useState<boolean>(false);

  const params = new Proxy(new URLSearchParams(history.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });

  const visibleVerifyAccountForm = (email: string) => {
    setEmailEntered(email);
    setFormDisplayed(WorkspaceSignInForm.VERIFIED_SEND_LINK);
  };

  useEffect(() => {
    const returnTo = params['return_to'];
    const redirect = params['redirect'];
    const isBlocked = params['isUserBlocked'];
    const isRemoved = params['isWorkerRemoved'];

    if (isBlocked) {
      dispatch.authStore.setIsUserBlocked(true);
    }
    if (isRemoved) {
      dispatch.authStore.setIsWorkerRemoved(true);
    }

    if (returnTo || redirect) {
      if (
        _.includes(returnTo, SIGN_IN_DOMAINS.GH_ZENDESK) ||
        _.includes(redirect, SIGN_IN_DOMAINS.GH_ME) ||
        _.includes(redirect, SIGN_IN_DOMAINS.LOCALHOST)
      ) {
        const resetPasswordAction = params['action'];
        if (resetPasswordAction === 'reset') {
          setFormDisplayed(WorkspaceSignInForm.SEND_RESET_LINK);
        }
      }
    }
  }, []);

  if (!params.redirect) return <Redirect to={loginPath()} />;

  return (
    <div className={css(commonStyles.registrationContainer)()}>
      <LeftSideBarSection
        screenType={SCREEN_TYPE.WORKSPACE_SIGN_IN}
        step={isCircleFullScreen ? WORKSPACE_SIGN_IN_STEPS.VALID_SIGN_IN : WORKSPACE_SIGN_IN_STEPS.START_SIGN_IN}
      />
      <FormWrapperSection>
        {formDisplayed === WorkspaceSignInForm.SIGN_IN && (
          <SignInForm
            onShowVerifySSOEmail={() => {
              setFormDisplayed(WorkspaceSignInForm.VERIFY_SSO_EMAIL);
            }}
            onShowForgotPassword={() => setFormDisplayed(WorkspaceSignInForm.SEND_RESET_LINK)}
            setIsCircleFullScreen={setIsCircleFullScreen}
          />
        )}
        {formDisplayed === WorkspaceSignInForm.SEND_RESET_LINK && (
          <ForgotPasswordForm onVisibleVerifyAccountForm={visibleVerifyAccountForm} />
        )}
        {formDisplayed === WorkspaceSignInForm.VERIFIED_SEND_LINK && (
          <VerifyAccountFormSection
            email={emailEntered}
            actionType={ActionType.SEND_RESET_LINK}
            screenType={SCREEN_TYPE.WORKSPACE_SIGN_IN}
          />
        )}
        {formDisplayed === WorkspaceSignInForm.VERIFY_SSO_EMAIL && (
          <VerifySamlEmailForm onGoBack={() => setFormDisplayed(WorkspaceSignInForm.SIGN_IN)} />
        )}
      </FormWrapperSection>
    </div>
  );
};

export default SignInView;
