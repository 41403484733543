import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { type ICompanyDetailForm } from 'views/interfaces';

export const validatePhoneNumber = (value: string | undefined, context: Yup.TestContext<ICompanyDetailForm>) => {
  if (!value || value === '') {
    return context.createError({ message: 'Please enter a phone number' });
  }

  const values = context.parent as ICompanyDetailForm;
  const phoneNumber = parsePhoneNumberFromString(value, values.workPhoneCountryCode);
  return phoneNumber?.isValid() || context.createError({ message: 'Ah, seems like you have an extra digit or two' });
};

export const validateABN = (value: string | undefined, context: Yup.TestContext<ICompanyDetailForm>) => {
  const values = context.parent as ICompanyDetailForm;
  if (values.workPhoneCountryCode !== 'AU') {
    return true;
  }

  if (!value || value === '') {
    return context.createError({ message: 'Please enter your company’s ABN' });
  }
  const regExp = /[a-zA-Z]/g;
  const formattedValue = value.split(' ').join('');
  let message = '';

  if (regExp.test(formattedValue))
    return context.createError({
      message: 'Looks like the number you entered is not a valid ABN. Please check the number and try again.',
    });

  if (formattedValue.length < 11)
    message = 'Looks like you’re missing some numbers here. Please double check and try again';
  if (formattedValue.length > 11) message = 'Hmm, that looks like too many numbers. Please double check and try again';

  return !message || context.createError({ message });
};

export const SetUpCompanyDetailsFormSchema = Yup.object().shape({
  workPhone: Yup.string()
    .trim()
    .required('Please enter a phone number')
    .test((options, ctx) => validatePhoneNumber(options, ctx as Yup.TestContext<ICompanyDetailForm>)),
  workPhoneCountryCode: Yup.string().required('Please select a country code'),
  companyName: Yup.string().trim().required('Please enter your company name'),
  companySize: Yup.string().required('Please select your company size'),
  abn: Yup.string()
    .trim()
    .test((options, ctx) => validateABN(options, ctx as Yup.TestContext<ICompanyDetailForm>)),
});

export const validatePassword = (value, context) => {
  const hasUpperCase = RegExp('(?=.*[A-Z])').test(value);
  const hasMinCharacter = RegExp('(?=.{8,})').test(value);
  const hasLowerCase = RegExp('(?=.*[a-z])').test(value);
  const hasNumber = RegExp('(?=.*[0-9])').test(value);
  const hasSpecial = RegExp('.*[^A-Za-z0-9]').test(value);
  const errorKeys = [];

  if (!hasUpperCase) errorKeys.push('ONE_UPPERCASE');
  if (!hasMinCharacter) errorKeys.push('MIN_CHARACTER');
  if (!hasLowerCase) errorKeys.push('ONE_LOWERCASE');
  if (!hasNumber) errorKeys.push('ONE_NUMBER');
  if (!hasSpecial) errorKeys.push('ONE_SPECIAL');

  return !errorKeys.length || context.createError({ message: errorKeys.join('|') });
};

export const AccountInformationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter an email address')
    .email('This email doesn’t look quite right, please check for any typos.'),

  firstName: Yup.string().required('Please enter your first name'),

  lastName: Yup.string().required('Please enter your last name'),

  password: Yup.string().required('Please enter password').test(validatePassword),
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please input your email.')
    .email('This email doesn’t look quite right, please check for any typos.'),
  password: Yup.string().required('Please input your password.'),
});

export const VerifyAccountScheme = Yup.object().shape({
  email: Yup.string()
    .required('Please enter an email address.')
    .email("This email domain doesn't look quite right. Please check for typos or extra spaces."),
});

export const ResetPasswordScheme = Yup.object().shape({
  password: Yup.string().required('Please enter new password').test(validatePassword),
  confirmPassword: Yup.string()
    .required('Please enter confirm new password')
    .oneOf([Yup.ref('password')], 'Confirm new password does not match'),
});

export const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Please enter your last name'),
  workPhone: Yup.string()
    .trim()
    .required('Please enter a phone number')
    .test((options, ctx) => validatePhoneNumber(options, ctx as Yup.TestContext<ICompanyDetailForm>)),
  workPhoneCountryCode: Yup.string().required('Please select a country code'),
  email: Yup.string()
    .required('Please enter an email address')
    .email('This email doesn’t look quite right, please check for any typos.'),
  password: Yup.string().required('Please enter password').test(validatePassword),
  confirmPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});
