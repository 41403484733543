import React from 'react';
import { IconContext } from '../../icon/icon-provider';

import type { PropsWithChildren } from 'react';
import type { IconProps } from '../..';

export type AlertTitleIconProviderProps = PropsWithChildren<IconProps>;

export function AlertTitleIconProvider(props: AlertTitleIconProviderProps) {
  let { children, ...otherProps } = props;

  const iconStyle = {
    height: '$size250',
    width: '$size250',
    flexShrink: 0,
  };

  otherProps = { ...iconStyle, ...otherProps };
  return <IconContext.Provider value={otherProps}>{children}</IconContext.Provider>;
}
