import React from 'react';

import { type IconStyleProps } from '../../theme';
import { type CountryCode } from 'variables/locales';
import { AustraliaFlag } from './australia-flag';
import { UnitedStatesFlag } from './united-states-flag';

type CountryFlagProps = IconStyleProps & {
  countryCode: CountryCode;
};

export const CountryFlag = ({ countryCode, ...iconProps }: CountryFlagProps) => {
  switch (countryCode) {
    case 'AU':
      return <AustraliaFlag {...iconProps} />;
    case 'US':
      return <UnitedStatesFlag {...iconProps} />;
    default:
      return null;
  }
};
