import React, { PureComponent } from 'react';

import { getPersistor } from '@rematch/persist';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

import rootStore from '../root-store';

const persistor = getPersistor();

class Gate extends PureComponent {
  render() {
    return (
      <Provider store={rootStore}>
        <PersistGate persistor={persistor}>
          {this.props.children}
          {/**/}
        </PersistGate>
      </Provider>
    );
  }
}

export default Gate;
