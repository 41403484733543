import { v4 } from 'uuid';
import { encode } from 'jwt-simple';

export const ZENDESK_SHARED_KEY = 'qB0IGOrMZyaYZoQnUpR0FPrn1lMtqvmmjHhWmqoZM2DndsQq';

export const generateZendeskRedirectUrl = ({
  user,
  hasPortalAccess,
  hasWorkerAppAccess,
  redirectUrl,
}: {
  user: {
    firstName: string;
    lastName: string | null;
    userId: string;
    email: string | null;
  };
  hasPortalAccess: boolean;
  hasWorkerAppAccess: boolean;
  redirectUrl: string;
}): string => {
  const tags = ['Consumers'];
  if (hasPortalAccess) tags.push('Admins');
  if (hasWorkerAppAccess) tags.push('Workers');

  const payload = {
    iat: Date.now() / 1000,
    jti: v4(),
    name: `${user.firstName} ${user.lastName ?? ''}`.trim(),
    email: user.email ?? `${user.userId}@goodhuman.me`,
    tags,
  };

  const token = encode(payload, ZENDESK_SHARED_KEY);
  return `https://goodhuman.zendesk.com/access/jwt?jwt=${token}&return_to=${redirectUrl}`;
};
