export enum SCREEN_TYPE {
  REGISTRATION_FLOW = 'REGISTRATION_FLOW',
  INVITED_MEMBER = 'INVITED_MEMBER',
  WORKSPACE_SIGN_IN = 'WORKSPACE_SIGN_IN',
  PASSWORD_RESET = 'PASSWORD_RESET',
}

export enum CircleSize {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
  xxlarge = 'xxlarge',
  fullScreen = 'fullScreen',
}

// SCREEN TYPE REGISTRATION_FLOW

export enum REGISTRATION_STEPS {
  START_SIGN_UP = 'START_SIGN_UP',
  VALID_SIGN_UP = 'VALID_SIGN_UP',
  VERIFY_EMAIL_OR_START_COMPANY = 'VERIFY_EMAIL_OR_START_COMPANY',
  VALID_COMPANY_DETAILS = 'VALID_COMPANY_DETAILS',
  START_PAYMENTS = 'START_PAYMENTS',
  VALID_PAYMENTS = 'VALID_PAYMENTS',
  SUCCESS_REGISTRATION_FLOW = 'SUCCESS_REGISTRATION_FLOW',
}

export const REGISTRATION_STEP_DETAILS = {
  [REGISTRATION_STEPS.START_SIGN_UP]: {
    step: 1,
    circleSize: CircleSize.xsmall,
    name: 'start sign up',
  },
  [REGISTRATION_STEPS.VALID_SIGN_UP]: {
    step: 2,
    circleSize: CircleSize.medium,
    name: 'valid sign up',
  },
  [REGISTRATION_STEPS.VERIFY_EMAIL_OR_START_COMPANY]: {
    step: 3,
    circleSize: CircleSize.large,
    name: 'verify email or start company details',
  },
  [REGISTRATION_STEPS.VALID_COMPANY_DETAILS]: {
    step: 4,
    circleSize: CircleSize.xlarge,
    name: 'valid company details',
  },
  [REGISTRATION_STEPS.START_PAYMENTS]: {
    step: 5,
    circleSize: CircleSize.xlarge,
    name: 'start payments',
  },
  [REGISTRATION_STEPS.VALID_PAYMENTS]: {
    step: 6,
    circleSize: CircleSize.xxlarge,
    name: 'valid payments',
  },
  [REGISTRATION_STEPS.SUCCESS_REGISTRATION_FLOW]: {
    step: 7,
    circleSize: CircleSize.small,
    name: 'success registration flow',
  },
};

// SCREEN TYPE INVITED MEMBER

export enum INVITED_STEPS {
  START_INVITED = 'START_INVITED',
  VALID_INVITED = 'VALID_INVITED',
}

export const INVITED_STEP_DETAILS = {
  [INVITED_STEPS.START_INVITED]: {
    step: 1,
    circleSize: CircleSize.xsmall,
    name: 'start invited',
  },
  [INVITED_STEPS.VALID_INVITED]: {
    step: 2,
    circleSize: CircleSize.fullScreen,
    name: 'valid invited',
  },
};

// SCREEN TYPE WORKSPACE SIGN IN

export enum WORKSPACE_SIGN_IN_STEPS {
  START_SIGN_IN = 'START_SIGN_IN',
  VALID_SIGN_IN = 'VALID_SIGN_IN',
}

export const WORKSPACE_SIGN_IN_STEP_DETAILS = {
  [WORKSPACE_SIGN_IN_STEPS.START_SIGN_IN]: {
    step: 1,
    circleSize: CircleSize.xsmall,
    name: 'start workspace sign in',
  },
  [WORKSPACE_SIGN_IN_STEPS.VALID_SIGN_IN]: {
    step: 2,
    circleSize: CircleSize.fullScreen,
    name: 'valid workspace sign in',
  },
};
// END SCREEN TYPE

export enum PASSWORD_ERRORS {
  ONE_UPPERCASE = 'Use at least one uppercase letter',
  ONE_LOWERCASE = 'At least one lower case letter',
  ONE_NUMBER = 'Use at least one number',
  ONE_SPECIAL = 'At least one special character',
  MIN_CHARACTER = 'Minimum 8 characters long',
}

export enum SIGN_UP_STATUS {
  START = 'START',
  SUBMITTED = 'SUBMITTED',
  SUBMITTED_WITH_REQUEST_ACCESS = 'SUBMITTED_WITH_REQUEST_ACCESS',
}

export enum ORGANISATION_STEPS {
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  PAYMENT = 'PAYMENT',
}

export const SIGN_IN_DOMAINS = {
  GH_ZENDESK: 'goodhuman.zendesk.com',
  GH_ME: 'goodhuman.me',
  LOCALHOST: 'localhost:3000',
};

export enum WorkspaceSignInForm {
  SIGN_IN = 'SIGN_IN',
  SEND_RESET_LINK = 'SEND_RESET_LINK',
  VERIFIED_SEND_LINK = 'VERIFIED_SEND_LINK',
  VERIFY_SSO_EMAIL = 'VERIFY_SSO_EMAIL',
}

export enum ActionType {
  RE_ENTER_NEW_PRIMARY_OWNER = 'RE_ENTER_NEW_PRIMARY_OWNER',
  SEND_RESET_LINK = 'SEND_RESET_LINK',
}

export const COMPANY_SIZE_OPTIONS = [
  {
    value: '2 - 10',
    label: '2 - 10 employees',
  },
  {
    value: '11 - 100',
    label: '11 - 100 employees',
  },
  {
    value: '101 - 500',
    label: '101 - 500 employees',
  },
  {
    value: '501 - 1,000',
    label: '501 - 1,000 employees',
  },
  {
    value: '1,001 - 5,000',
    label: '1,001 - 5,000 employees',
  },
  {
    value: '5,001+',
    label: '5,001+ employees',
  },
  {
    value: 'Sole Trader',
    label: 'Sole Trader',
  },
];

export const WORKSPACE_SIGN_IN_LINKS = {
  TERMS_OF_USE: 'https://www.goodhuman.me/terms-of-use',
  GET_START_HERE: 'https://www.goodhuman.me',
};

export const verifyAccountFormExtensions = {
  [SCREEN_TYPE.REGISTRATION_FLOW]: {
    shortSentence: 'to verify your details',
  },
  [SCREEN_TYPE.WORKSPACE_SIGN_IN]: {
    shortSentence: 'with a link to reset your password',
  },
};

export enum PASSWORD_RESET_STEPS {
  START_PASSWORD_RESET = 'START_PASSWORD_RESET',
}

export const PASSWORD_RESET_STEP_DETAILS = {
  [PASSWORD_RESET_STEPS.START_PASSWORD_RESET]: {
    step: 1,
    circleSize: CircleSize.xsmall,
    name: 'reset password',
  },
};
