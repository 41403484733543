import { createTRPCProxyClient, type CreateTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { getAuth } from 'firebase/auth';
import type { AppRouter } from '@good/auth-service';
import { APP_CONFIG } from 'variables/app-config';

export const trpc: CreateTRPCProxyClient<AppRouter> = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${APP_CONFIG.AUTH_SERVICE_ORIGIN}/trpc`,
      async headers() {
        const currentUser = getAuth().currentUser;
        const idToken = await currentUser?.getIdToken();

        return {
          authorization: idToken ? `Bearer ${idToken}` : undefined,
        };
      },
    }),
  ],
});
