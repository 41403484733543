import { APP_CONFIG } from 'variables/app-config';

const workspaceRedirectionLocation = (): string => {
  const currentUrl = new URL(document.URL);
  return currentUrl.searchParams.get('redirect') ?? APP_CONFIG.REDIRECT_URL;
};

export const workspaceRedirectionUrl = ({
  token,
  serviceProviderId,
}: {
  token?: string | undefined;
  serviceProviderId?: string | undefined;
} = {}) => {
  const redirectUrl = new URL(workspaceRedirectionLocation());
  if (token) redirectUrl.searchParams.set('token', token);
  if (serviceProviderId) redirectUrl.searchParams.set('serviceProviderId', serviceProviderId);
  return redirectUrl;
};

export const pathWithWorkspaceRedirect = (path: string): string => {
  // helper to keep the Workspace URL redirect in the query string across pages
  const rootedPath = path.startsWith('/') ? path.slice(1) : path;
  return `/${rootedPath}?redirect=${workspaceRedirectionLocation()}`;
};

export const loginPath = (): string => {
  return pathWithWorkspaceRedirect('/login');
};
