import React from 'react';
import { useIdentifyLDUser } from 'integrations/launch-darkly';
import { Route } from 'react-router-dom';
import RegisterView from 'views/RegisterView';
import AccountInformationView from 'views/AccountInformationView';
import OrganisationView from 'views/OrganisationView';
import ResetPasswordView from 'views/ResetPasswordView';
import SignInView from 'views/SignInView';
import { ProviderSelectionView } from 'views/provider-selection-view';
import { InvitationView } from 'views/invitation-view';

const Routes = (): JSX.Element => {
  void useIdentifyLDUser();

  return (
    <>
      <Route path='/register' component={RegisterView} />
      <Route path='/invitation' component={InvitationView} />
      <Route path='/create-account/profile' component={AccountInformationView} />
      <Route path='/create-account/company-details' component={OrganisationView} />
      <Route path='/reset-password' component={ResetPasswordView} />
      <Route path='/login' component={SignInView} />
      <Route path='/providers' component={ProviderSelectionView} />
    </>
  );
};

export default Routes;
