import React from 'react';
import { Button, Heading } from 'registration-flow-v2';
import { useHistory } from 'react-router-dom';
import { loginPath } from 'utilities/navigation';

const UnauthorizeMessage = () => {
  const history = useHistory();
  return (
    <>
      <Heading level={3} emphasis="regular" marginBottom="$space400">
        It looks like you’ve already finished registering this account! Sign in to access your workspace.
      </Heading>
      <Button
        kind="accent"
        emphasis="filled"
        type="submit"
        width="$sizeFull"
        color="$white"
        backgroundColor="$ocean"
        marginTop="auto"
        size="large"
        borderWidth="$border0"
        onClick={() => history.push(loginPath())}
      >
        Sign in
      </Button>
    </>
  );
};

export default UnauthorizeMessage;
