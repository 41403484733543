import { css, CSS } from 'registration-flow-v2/theme/index';

export const leftSideBarContainer: CSS = {
  '@xxxlarge': {
    position: 'relative',
  },
};

export const leftSideBar: CSS = {
  height: '100%',
  display: 'none',
  backgroundColor: '$canaryLight2',

  '@xlarge': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '$transition100',
    paddingRight: '$space900',
  },
  '@xxxlarge': {
    paddingRight: '$space550',
  },
};

export const logo: CSS = {
  height: 'auto',
  zIndex: 2,
  transform: 'translateZ(1px)',

  '@xlarge': {
    paddingRight: '$space1800',
  },
  '@xxxlarge': {
    paddingRight: '$space3200',
  },
};

export const person: CSS = {
  position: 'relative',
  height: 'auto',
  zIndex: 2,
};

export const variants = css({
  position: 'absolute',
  borderRadius: '$full',
  backgroundColor: '$yellow',
  width: '$size3000',
  height: '$size3000',
  transition: 'all 2s ease-in-out',
  right: '$space900',

  variants: {
    size: {
      xsmall: {
        scale: 0.9,
      },
      small: {
        scale: 1,
      },
      medium: {
        scale: 3,
      },
      large: {
        scale: 4.2,
      },
      xlarge: {
        scale: 5.6,
        '@xxxlarge': {
          scale: 5.9,
        },
      },
      xxlarge: {
        scale: 6,
        '@xxxlarge': {
          scale: 6.6,
        },
      },
      fullScreen: {
        scale: 20,
        '@xxxlarge': {
          scale: 100,
        },
      },
    },
  },
});
