/* eslint-disable */
// ? ApiClient for accessing the API endpoints
import axios from 'axios';
import Utils from './Utils';

// ! Edit from Jir : Added Firebase App directly here
import { getAuth } from 'firebase/auth';

import rootStore from '../stores/rematch/root-store';

import { APP_CONFIG } from 'variables/app-config';
import { backOff } from 'exponential-backoff';

const { isEmpty } = Utils;

// faux delay
// const asyncDelay = (ms) => new Promise((r) => setTimeout(r, ms));

// ? Debug mode will bypass

// Hardcoded for now, to remove entirely later.
const apiBaseUrl = APP_CONFIG.API_URL;

const backoffOptions = {
  maxDelay: 3000,
  numOfAttempts: 6,
  startingDelay: 1000,
  timeMultiple: 2,
  retry: (e) => e?.status === 502, // retry only on 502 errors
};

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */

const getClient = (baseUrl = apiBaseUrl) => {
  const options = {
    baseURL: baseUrl,
  };

  // ? Use interceptors instead as the token will keep changing
  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    async (requestConfig) => {
      let interceptedConfig = { ...requestConfig };

      if (interceptedConfig.anonymousRequest) {
        // ! Anonymous Request - Some endpoints are anonymous, eg checkIfMobileExists. Don't append the Authorized Bearer header
        // console.log('anonymous request');
      } else {
        // ! Authenticated Request - use Authorization Bearer token
        // console.log('authenticated request');

        // console.log('authenticating as normal mode');
        // ! Normal mode. Uses Firebase Auth Tokens

        // ! Edit by Jir : Get current User from Firebase
        const currentUser = await getAuth().currentUser;

        if (currentUser) {
          // console.log(`Authenticating as uid : ${currentUser.uid}`);

          // TODO note: This is assuming that getIdToken will cache and refresh as necessary. Need to test it out.
          const idToken = await currentUser.getIdToken();
          // const idToken = await currentUser.getIdToken(true);

          // console.log(idToken);

          interceptedConfig.headers.Authorization = `Bearer ${idToken}`;

          //! for development use only
          // TODO this looks dodgy... need to update this later
          const portalUser = rootStore.getState().authStore.portalUser;
          if (portalUser) {
            interceptedConfig.headers.serviceproviderid = portalUser.serviceProviderId;
          }
        } else {
          console.warn('Warning - no current user for Firebase. No Authorization Bearer token will be passed in');
        }
      }

      //// console.log(interceptedConfig);

      return interceptedConfig;
    },
    (requestError) => {
      // TODO : Send this over to a logging facility
      return Promise.reject(requestError);
    },
  );

  // ! Responses are wrapped with the following object format:
  //  *    {
  //  *      status   : http status code
  //  *      data     : actual data
  //  *      meta     : meta response
  //  *      message  : error message (only for errors)
  //  *    }

  client.interceptors.response.use(
    (response) => {
      const { status, data: rawData } = response;
      let data, meta;
      // Prevent undefined errors later...
      if (isEmpty(rawData)) {
        data = {};
        meta = {};
      } else {
        data = rawData.data;
        meta = rawData.meta;
      }

      return { status, data, meta };
    },
    (error) => {
      // Handle exceptions/invalid logs here
      const { status, data: rawData } = error.response;

      let data, meta;

      // Prevent undefined errors later...
      if (isEmpty(rawData)) {
        data = {};
        meta = {};
      } else {
        data = rawData.data;
        meta = rawData.meta;
      }

      return Promise.reject({ status, data, meta, message: error.message });
    },
  );

  return client;
};

class ApiClient {
  client = {};

  constructor(baseUrl = apiBaseUrl) {
    this.client = getClient(baseUrl);
  }

  download(url, conf = {}) {
    return this.client.pipe(url, conf).then();
  }

  async get(url, conf = {}) {
    return await backOff(() => this.client.get(url, conf), backoffOptions);
  }

  // ! Anonymous request
  async getAsAnonymous(url, conf = {}) {
    return await backOff(() => this.client.get(url, { ...conf, anonymousRequest: true }), backoffOptions);
  }

  async delete(url, conf = {}) {
    return await backOff(() => this.client.delete(url, { data: conf }), backoffOptions);
  }

  async head(url, conf = {}) {
    return await backOff(() => this.client.head(url, conf), backoffOptions);
  }

  async options(url, conf = {}) {
    return await backOff(() => this.client.options(url, conf), backoffOptions);
  }

  async post(url, data = {}, conf = {}) {
    return await backOff(() => this.client.post(url, data, conf), backoffOptions);
  }

  async put(url, data = {}, conf = {}) {
    return await backOff(() => this.client.put(url, data, conf), backoffOptions);
  }

  async patch(url, data = {}, conf = {}) {
    return await backOff(() => this.client.patch(url, data, conf), backoffOptions);
  }
}

const defaultClient = new ApiClient();

// ? Default imports an instantiated client.
export default defaultClient;

// ? Alternatively, construct it yourself if there's another url that you need to access.
// Example : const client = new ApiClient('baseUrl');

export { ApiClient };
