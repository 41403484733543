import React, { useState } from 'react';

import { css } from 'registration-flow-v2';
import FormWrapperSection from 'views/components/FormWrapperSection';
import LeftSideBarSection from 'views/components/LeftSideBarSection';
import * as commonStyles from 'views/css/common-onboarding.css';
import RequestSentSection from './components/RequestSentSection';
import { ActionType, REGISTRATION_STEPS, SCREEN_TYPE, SIGN_UP_STATUS } from './constants';
import AccountInformationForm from './forms/AccountInformationForm';
import VerifyAccountForm from './forms/VerifyAccountForm';
import { IAccountInformationForm } from './interfaces';
import { Flag } from 'common-components/utils/launch-darkly/flag';
import ErrorStateMessage from './components/ErrorStateMessage';

const AccountInformation = (): JSX.Element => {
  const [step, setStep] = useState<REGISTRATION_STEPS>(REGISTRATION_STEPS.START_SIGN_UP);
  const [signUpStatus, setSignUpStatus] = useState<string>(SIGN_UP_STATUS.START);
  const [accountInformationFormValues, setAccountInformationFormValues] = useState<IAccountInformationForm>();

  return (
    <div className={css(commonStyles.registrationContainer)()}>
      <LeftSideBarSection screenType={SCREEN_TYPE.REGISTRATION_FLOW} step={step} />

      <FormWrapperSection hasProgressBar screenType={SCREEN_TYPE.REGISTRATION_FLOW} step={step}>
        <Flag
          flag="selfServiceRegistration"
          off={<ErrorStateMessage />}
          on={
            <>
              {signUpStatus === SIGN_UP_STATUS.START && (
                <AccountInformationForm
                  setStep={setStep}
                  setSignUpStatus={setSignUpStatus}
                  setAccountInformationFormValues={setAccountInformationFormValues}
                />
              )}
              {signUpStatus === SIGN_UP_STATUS.SUBMITTED && (
                <VerifyAccountForm
                  email={accountInformationFormValues.email}
                  setStep={setStep}
                  setSignUpStatus={setSignUpStatus}
                  actionType={ActionType.RE_ENTER_NEW_PRIMARY_OWNER}
                  screenType={SCREEN_TYPE.REGISTRATION_FLOW}
                />
              )}
              {signUpStatus === SIGN_UP_STATUS.SUBMITTED_WITH_REQUEST_ACCESS && <RequestSentSection />}
            </>
          }
        />
      </FormWrapperSection>
    </div>
  );
};

export default AccountInformation;
