import { CSS } from 'registration-flow-v2';

export const modalFooterActions: CSS = {
  display: 'flex',
  gap: '$space200',

  '@initial': {
    justifyContent: 'space-between',
  },
  '@small': {
    justifyContent: 'flex-end',
  },
};
