import { CSS } from 'registration-flow-v2';

export const text: CSS = {
  color: '$black',
  fontFamily: '$primary',
  fontWeight: '$normal',
  fontSize: '$font400',
  lineHeight: '$leading800',
  marginBottom: '$space600',
};

export const textBold: CSS = {
  fontWeight: '$bold',
};

export const artWordWrapper: CSS = {
  margin: '0 auto',
  width: '$size1600',
  height: '$size1600',
  backgroundColor: '$yellow',
  borderRadius: '100%',
  position: 'relative',
};

export const paperPlane: CSS = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  width: '$size2000',
};
