import { init, RematchRootState } from '@rematch/core';

import _ from 'lodash';

import createRematchPersist from '@rematch/persist';
// import createLoadingPlugin from '@rematch/loading';

// Note : Add models inside /models, and import it here
import authStore from 'stores/rematch/models/auth-store';
import stripeStore from 'stores/rematch/models/stripe-store';

import { createTransform } from 'redux-persist';

// The following intercepts the persist hydrated state, and transforms it to a new state.
// Why? Because isAppLoaded is persisted as true; it's cleaner to reset it to false every time it's re-hydrated.
const SetTransform = createTransform(
  null,
  (outbound) => {
    // clone state and reset app loaded property to false.
    const newState: any = _.cloneDeep(outbound);

    newState.isAppLoaded = false;

    // We can add any state that we wish to reset here, eg
    // newState.currentUser = null;

    return newState;
  },
  { whitelist: [] },
);

// Persist Plugin for Rematch
const persistPlugin = createRematchPersist({
  // storage: AsyncStorage,
  // whitelist: ['userStore', 'authStore']
  whitelist: [],
  transforms: [SetTransform],
  // whitelist: ['authStore', 'servicesStore']
  // blacklist: ['non-auth'],{}
  // throttle: 5000,
  // version: 1,
});

// Loading indicator Plugin for Rematch
// const loadingPlugin = createLoadingPlugin({
//   name: 'loading',
//   asNumber: false
//   // whitelist: [],
//   // blacklist: [],
// });

const models = {
  // Add imported stores here
  authStore,
  stripeStore,
};

const rootStore = init({
  models,
  plugins: [persistPlugin],

  // take out loadingPlugin. Not very useful
  // plugins: [persistPlugin, loadingPlugin]
});

export default rootStore;

export type IStore = typeof rootStore;
export type IRootDispatch = typeof rootStore.dispatch;
export type IRootState = RematchRootState<typeof models>;

export var dispatch: IRootDispatch;
export var state: IRootState;
