import React, { useRef } from 'react';

import { useOption } from '@react-aria/listbox';
import { ListState } from '@react-stately/list';
import { Node } from '@react-types/shared';
import { compose, css } from '../../theme';
import type { OptionsVariants } from './list-box-option.css';
import * as styles from './list-box-option.css';

export type ListBoxOptionProps = OptionsVariants & {
  item: Node<unknown>;
  state: ListState<unknown>;
};

/**
 *
 * @name
 * ListBoxOption
 *
 * @description
 * Component used in ListBox
 *
 */
export function ListBoxOption(props: ListBoxOptionProps) {
  const { item, state } = props;
  const ref = useRef<HTMLLIElement>(null);

  const { optionProps, isSelected, isFocused } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  );

  return (
    <li
      ref={ref}
      {...optionProps}
      className={compose(
        css(styles.reset, styles.option),
        styles.optionVariants({
          isFocused,
          isSelected,
        }),
      )}
    >
      {item.props.prefix ?? ''}
      {item.rendered}
    </li>
  );
}
