import React, { useState } from 'react';
import { Button, css, InputField, Modal, Text } from 'registration-flow-v2';
import { Textarea } from 'registration-flow-v2/textarea';
import { isMobile } from 'react-device-detect';
import * as styles from '../css/css-components/RequestAccessModal.css';

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSendRequest: (value: string) => void;
}

const RequestAccessModal = (props: IProps) => {
  const { isOpen, onClose, onSendRequest, isLoading } = props;

  const [message, setMessage] = useState<string>('');

  return (
    <Modal
      header="Request access"
      onClose={onClose}
      isOpen={isOpen}
      footer={
        <div className={css(styles.modalFooterActions)()}>
          <Button
            emphasis="quiet"
            onPress={onClose}
            isDisabled={isLoading}
            flex={isMobile ? 1 : 'unset'}
            paddingX={isMobile ? '$space300' : '$space250'}
            paddingY={isMobile ? '$space200' : '$space130'}
          >
            Cancel
          </Button>
          <Button
            emphasis="filled"
            onPress={() => onSendRequest(message)}
            isLoading={isLoading}
            flex={isMobile ? 1 : 'unset'}
            paddingX={isMobile ? '$space300' : '$space250'}
            paddingY={isMobile ? '$space200' : '$space130'}
          >
            Send request
          </Button>
        </div>
      }
    >
      <Text color="$black">
        We’ll request access to your company’s GoodHuman workspace on your behalf. If you want, add a message to go with
        your request.{' '}
      </Text>
      <InputField
        {...(!isMobile && { label: 'Add message', isRequired: false })}
        marginTop="$space300"
        marginBottom="$space175"
      >
        <Textarea placeholder="Placeholder" onChange={(value) => setMessage(value)} />
      </InputField>
    </Modal>
  );
};

export default RequestAccessModal;
