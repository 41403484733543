import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const reset: CSS = {
  display: 'flex',
  flexDirection: 'row',
  fontFamily: '$primary',
  alignItems: 'flex-start',
  gap: '$space100',
  outline: 'none',
};

// Wrapper css
export const wrapper: CSS = {
  borderWidth: '$thin',
  borderColor: 'transparent',
  borderStyle: '$solid',
  outline: 'none',
};

// Wrapper variants
export const wrapperVariants = css({
  variants: {
    size: {
      small: {
        borderRadius: '$xsmall',
      },
      medium: {
        borderRadius: '$small',
      },
    },
    isFocused: {
      true: {
        padding: '$space20',
        borderColor: '$accent',
      },
    },
  },
});

// Checkbox css
export const checkbox: CSS = {
  borderWidth: '$thin',
  borderColor: '$body',
  borderStyle: '$solid',
  backgroundColor: '$white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

// Checkbox Variants
export const checkboxVariants = css({
  variants: {
    size: {
      small: {
        width: '$size200',
        height: '$size200',
        borderRadius: '$xsmall',
      },
      medium: {
        width: '$size250',
        height: '$size250',
        borderRadius: '$small',
      },
    },
    isChecked: {
      true: {
        borderColor: '$accent',
        backgroundColor: '$accent',
      },
    },
    isDisabled: {
      true: {
        borderColor: '$neutral !important',
        backgroundColor: '$neutral !important',
      },
    },
    isPressed: {
      true: {
        backgroundColor: '$accentBackground',
        borderColor: '$accentPressed',
      },
    },
    isFocused: {
      true: {
        borderColor: 'transparent',
        backgroundColor: '$accentBackgroundSecondary',
      },
    },
    isIndeterminate: {
      true: {
        backgroundColor: '$accentBackground',
        borderColor: '$accent',
      },
    },
  },
  compoundVariants: [
    {
      isPressed: true,
      isChecked: true,
      css: {
        backgroundColor: '$accentPressed',
        borderColor: '$accentPressed',
      },
    },
    {
      isIndeterminate: true,
      isPressed: true,
      css: {
        backgroundColor: '$accentBackground',
        borderColor: '$accentPressed',
      },
    },
    {
      isIndeterminate: true,
      isFocused: true,
      css: {
        backgroundColor: '$accentBackgroundSecondary',
      },
    },
    {
      isChecked: true,
      isFocused: true,
      css: {
        backgroundColor: '$accent',
      },
    },
  ],
});

// Icon indeterminate Css
export const iconIndeterminate: CSS = {
  display: 'inline-block',
};

// Variants icon indeterminate
export const iconIndeterminateVariants = css({
  variants: {
    size: {
      small: {
        width: '$size75',
        height: '$size10',
      },
      medium: {
        width: '$size100',
        height: '$size25',
      },
    },
    isDisabled: {
      true: {
        backgroundColor: '$bodyDark1 !important',
      },
    },
    isIndeterminate: {
      true: {
        backgroundColor: '$ocean',
      },
    },
  },
});

// Text group css
export const textGroup: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$space20',
};

// Label text css
export const labelText: CSS = {
  color: '$bodyDark2',
};

// Label text variants
export const labelTextVariants = css({
  variants: {
    size: {
      small: {
        fontSize: '$font75',
        lineHeight: '$leading400',
      },
      medium: {
        fontSize: '$font100',
        lineHeight: '$leading400',
      },
    },
    emphasis: {
      regular: {
        fontWeight: '$normal',
      },
      bold: {
        fontWeight: '$bold',
      },
    },
  },
});

// Support text
export const supportText: CSS = {
  fontWeight: '$normal',
  color: '$bodyDark1',
};

// Support text variants
export const supportTextVariants = css({
  variants: {
    size: {
      small: {
        fontSize: '$font50',
        lineHeight: '$leading100',
      },
      medium: {
        fontSize: '$font75',
        lineHeight: '$leading400',
      },
    },
  },
});

export type WrapperVariants = Stitches.VariantProps<typeof wrapperVariants>;
export type CheckboxVariants = Stitches.VariantProps<typeof checkboxVariants>;
export type LabelTextVariants = Stitches.VariantProps<typeof labelTextVariants>;
export type SupportTextVariants = Stitches.VariantProps<typeof supportTextVariants>;
