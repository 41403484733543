import React, { Component } from 'react';
import { Text } from 'common-components/typography';

import styled from 'styled-components';

// background-color: ${BlueprintColors.DARK_GRAY3};

const LogoContainer = styled.div`
  background-color: transparent;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  height: 64px;
`;

export default class NavLogo extends Component<{ sideMenuCollapsed: boolean }> {
  render() {
    return (
      <LogoContainer style={{}}>
        <Text style={{ fontSize: '20px', color: 'white' }}>{this.props.sideMenuCollapsed ? 'GH' : 'GoodHuman'}</Text>
      </LogoContainer>
    );
  }
}
