import React from 'react';
import { Heading, Text } from 'registration-flow-v2';

const ErrorStateMessage = () => {
  return (
    <>
      <Heading level={2} emphasis="regular" marginBottom="$space100">
        Oops! Something went wrong.
      </Heading>
      <Text color="$black" size="medium" marginBottom="$space300">
        Please check with the{' '}
        <Text emphasis="bold" fontSize="inherit !important">
          GoodHuman
        </Text>{' '}
        team, and they'll help sort this out for you.
      </Text>
    </>
  );
};

export default ErrorStateMessage;
