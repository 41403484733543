import { Slot as _Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';
import { styled } from '@stitches/react';

import { boxStyleProps, css, useStyleProps } from '..';

import type { BoxStyleProps, HTMLProps, SlotProps } from '..';
import type { PropsWithChildren } from 'react';

export type StackProps = SlotProps & HTMLProps<HTMLDivElement> & BoxStyleProps & PropsWithChildren<{}>;

const Div = styled('div');
const Slot = styled(_Slot);

/**
 * @name
 * Stack
 *
 * @description
 * The `Stack` is designed to literally 'stack' items on top of each
 * other while maintaining a consistent gutter between each item.
 */

export const Stack = forwardRef<HTMLDivElement, StackProps>(function Stack(props, ref) {
  let { styleProps, ...otherProps } = useStyleProps(props, boxStyleProps);
  let { asChild, children } = props;
  let ElementType = asChild ? Slot : Div;

  return (
    <ElementType
      {...filterDOMProps(otherProps)}
      className={css({
        ...styleProps,
        display: 'flex',
        flexDirection: 'column',
      })()}
      ref={ref}
    >
      {children}
    </ElementType>
  );
});
