import React from 'react';
import { reactTrpc } from 'utilities/react-trpc';
import { Text } from '@good/components';
import { Route } from 'react-router-dom';
import { ServiceProviderCard } from './service-provider-card';
import { workspaceRedirectionUrl } from 'utilities/navigation';

type Properties = {
  providers: {
    serviceProviderId: string;
    serviceProviderName: string;
  }[];
};

export const ServiceProviderSelection = ({ providers }: Properties) => {
  const login = reactTrpc.auth.login.useMutation();

  return (
    <div className="flex flex-col gap-4">
      {providers.map((provider) => (
        <ServiceProviderCard
          key={provider.serviceProviderId}
          serviceProviderName={provider.serviceProviderName}
          onClick={() => login.mutate({ serviceProviderId: provider.serviceProviderId })}
          isLoading={
            (login.isLoading || login.isSuccess) && login.variables?.serviceProviderId === provider.serviceProviderId
          }
          isDisabled={login.isLoading || login.isSuccess}
        />
      ))}

      {login.error && <Text>{login.error.message}</Text>}

      {login.isSuccess && (
        <Route
          component={() => {
            window.location.assign(
              workspaceRedirectionUrl({
                token: login.data.token,
                serviceProviderId: login.variables?.serviceProviderId,
              }),
            );
            return null;
          }}
        />
      )}
    </div>
  );
};
