import React from 'react';
import { Icon } from '../icon';
import type { IconProps } from '../icon-props';

export function Loading(props: IconProps) {
  return (
    <Icon {...props}>
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={16}
        d='M28,80 a112,112 0 1,0 100,-64'
      />
    </Icon>
  );
}
