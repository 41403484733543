import { useMemo } from 'react';

/**
 * Useful for retrieving search params before the page has mounted and becomes available with useParams.
 * React router hooks are not available until the page has completed hydration, so this hook can be used to control page flow safely before that point.
 * @param param the search param to retrieve
 * @returns the value of the search param if it exists, otherwise undefined
 */
export const useSearchParam = (param: string): string | undefined => {
  const path = window.location.href;

  const value = useMemo(() => {
    const match = path.match(new RegExp(`[&?]${param}=(.*?)(&|$)`));
    if (!match?.[1]) return undefined;
    return decodeURIComponent(match[1]);
  }, [path, param]);

  return value;
};
