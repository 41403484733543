import React, { createContext, PropsWithChildren } from 'react';
import { InputStylesProps } from 'registration-flow-v2/theme';

export type InputGroupProviderProps = PropsWithChildren<
  InputStylesProps & {
    isInsideGroupInput?: boolean;
    isDisabled?: boolean;
  }
>;

export const InputGroupContext = createContext({} as InputGroupProviderProps);

export function InputGroupProvider(props: InputGroupProviderProps) {
  const { children, ...otherProps } = props;

  return <InputGroupContext.Provider value={otherProps}>{children}</InputGroupContext.Provider>;
}
