import { notification } from 'antd';
import { FormikProps, useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Button, css, EyeClose, EyeOpen, Heading, InputField, Link, TextField } from 'registration-flow-v2';
import { EValidationStatus } from 'registration-flow-v2/types';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { LoginSchema } from 'utilities/form-utils';
import { WORKSPACE_SIGN_IN_LINKS } from '../constants';
import { ISignInForm } from '../interfaces';
import { Text as _Text, Link as _Link } from '@good/components';
import { Flag } from 'common-components/utils/launch-darkly/flag';

interface IProps {
  onShowForgotPassword: () => void;
  onShowVerifySSOEmail: () => void;
  setIsCircleFullScreen: (value: boolean) => void;
}

const SignInForm = (props: IProps) => {
  const { onShowForgotPassword, setIsCircleFullScreen, onShowVerifySSOEmail } = props;

  const { isWorkerRemoved, isUserBlocked } = useSelector((state: IRootState) => ({
    isWorkerRemoved: state.authStore.isWorkerRemoved,
    isUserBlocked: state.authStore.isUserBlocked,
  }));

  const dispatch = useDispatch<IRootDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  const formik: FormikProps<ISignInForm> = useFormik<ISignInForm>({
    initialValues: { email: '', password: '' },
    validationSchema: LoginSchema,
    onSubmit: () => submitForm(),
  });

  const { values, isValid, dirty, touched, errors, handleSubmit, handleBlur, setFieldValue } = formik;

  const submitForm = async () => {
    setIsLoading(true);
    try {
      const payload = {
        login: values.email,
        password: values.password,
      };
      await dispatch.authStore.doSignIn(payload);
      setIsCircleFullScreen(true);
      notification.success({
        message: 'Sign in success!',
        description: 'Redirecting to workspace ...',
      });
    } catch (error) {
      setIsCircleFullScreen(false);
      formik.setFieldError('email', error.message);
      formik.setFieldError('password', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const showPassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  const loginWithSaml = () => {
    onShowVerifySSOEmail();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box marginBottom='$space300'>
        <Heading level={2} emphasis='regular'>
          Sign in to manage your services
        </Heading>
      </Box>

      <InputField label='Email' marginBottom='$space300'>
        <TextField
          name='email'
          type='email'
          placeholder='Enter email here'
          onChange={(value: string) => setFieldValue('email', value)}
          value={values.email}
          validations={touched.email && errors.email && [{ status: EValidationStatus.Invalid, message: errors.email }]}
          onBlur={handleBlur}
          autoFocus
        />
      </InputField>

      <InputField label='Password'>
        <TextField
          name='password'
          label='Password'
          type={visiblePassword ? 'text' : 'password'}
          placeholder='Enter password here'
          suffix={
            <span onClick={showPassword} className={css({ cursor: 'pointer' })()}>
              {visiblePassword ? <EyeClose color='$bodyDark1' /> : <EyeOpen color='$bodyDark1' />}
            </span>
          }
          validations={
            touched.password && errors.password && [{ status: EValidationStatus.Invalid, message: errors.password }]
          }
          onBlur={handleBlur}
          value={values.password}
          onChange={(value) => setFieldValue('password', value)}
        />
      </InputField>

      {isWorkerRemoved && (
        <Box marginTop='$space150'>
          <Alert title='User revoked' emphasis='filled' tone='danger' closeable={false}>
            This account has had its access revoked. Please contact your employer if you think this is a mistake.
          </Alert>
        </Box>
      )}

      {isUserBlocked && (
        <Box marginTop='$space150'>
          <Alert title='User blocked' emphasis='filled' tone='danger' closeable={false}>
            You have been logged out from GoodHuman - please sign in again.
          </Alert>
        </Box>
      )}

      <Box display='flex' justifyContent='end' marginTop='$space175'>
        <Link emphasis='bold' size='small' onPress={onShowForgotPassword}>
          Forgot your password?
        </Link>
      </Box>

      <Box marginTop='$space300'>
        <_Text>
          <_Text>By logging in, you agree to GoodHuman’s</_Text>{' '}
          <_Link>
            <a href='https://goodhuman.me/terms-of-use/'>Terms of Use</a>
          </_Link>
          .
        </_Text>
      </Box>

      <Button
        kind='accent'
        emphasis='filled'
        type='submit'
        width='$sizeFull'
        color='$white'
        backgroundColor='$ocean'
        marginTop='$large'
        size='large'
        borderWidth='$border0'
        isDisabled={!(isValid && dirty)}
        isLoading={isLoading}
      >
        Sign in
      </Button>

      <Flag
        flag='singleSignOn'
        on={
          <Box marginTop='$space225' display='flex' alignItems='center' justifyContent='center'>
            <_Text>
              <Link emphasis='no-underline' size='small' onPress={loginWithSaml}>
                Single sign on
              </Link>
            </_Text>
          </Box>
        }
      />

      <Box marginTop='$space225' display='flex' alignItems='center' justifyContent='center'>
        <_Text>
          Don’t have an account?{' '}
          <_Link emphasis='no-underline'>
            <a href={WORKSPACE_SIGN_IN_LINKS.GET_START_HERE}>Get started here</a>
          </_Link>
        </_Text>
      </Box>
    </form>
  );
};

export default SignInForm;
