import { css } from '../theme';

import type { CSS } from '../theme';

export const tooltip: CSS = {
  position: 'relative',
  lineHeight: 'initial',
};

export const tooltipContent: CSS = {
  position: 'absolute',
  zIndex: '111111',
};

export const arrowTooltip = {
  position: 'absolute',
  width: '0',
  height: '0',
  borderLeft: '9px solid transparent',
  borderRight: '9px solid transparent',
  borderBottom: '9px solid $lavenderDark2',
};

export const tooltipContentVariants = css({
  top: '$$tooltipTop',
  bottom: '$$tooltipBottom',
  left: '$$tooltipLeft',
  right: '$$tooltipRight',

  variants: {
    placement: {
      top: {
        $$tooltipBottom: '32px',
        $$tooltipLeft: '50%',
        transform: 'translateX(-50%)',
      },
      'top-left': {
        $$tooltipBottom: '32px',
        $$tooltipLeft: '-12px',
      },
      'top-right': {
        $$tooltipBottom: '32px',
        $$tooltipRight: '-12px',
      },
      bottom: {
        $$tooltipTop: '32px',
        $$tooltipLeft: '50%',
        transform: 'translateX(-50%)',
      },
      'bottom-left': {
        $$tooltipTop: '32px',
        $$tooltipLeft: '-12px',
      },
      'bottom-right': {
        $$tooltipTop: '32px',
        $$tooltipRight: '-12px',
      },
    },
  },
});

export const arrowTooltipVariants = css({
  top: '$$tooltipArrowTop',
  bottom: '$$tooltipArrowBottom',
  left: '$$tooltipArrowLeft',
  right: '$$tooltipArrowRight',

  variants: {
    placement: {
      top: {
        $$tooltipArrowBottom: '-8px',
        left: '50%',
        transform: 'translate(-50%) rotate(180deg)',
      },
      'top-left': {
        $$tooltipArrowBottom: '-8px',
        left: 'calc($$tooltipLeft * -1)',
        transform: 'rotate(180deg)',
      },
      'top-right': {
        $$tooltipArrowBottom: '-8px',
        right: 'calc($$tooltipRight * -1)',
        transform: 'rotate(180deg)',
      },

      bottom: {
        $$tooltipArrowTop: '-8px',
        left: '50%',
        transform: 'translate(-50%) ',
      },
      'bottom-left': {
        $$tooltipArrowTop: '-8px',
        left: 'calc($$tooltipLeft * -1)',
      },
      'bottom-right': {
        $$tooltipArrowTop: '-8px',
        right: 'calc($$tooltipRight * -1)',
      },
    },
  },
});
