import React, { forwardRef } from 'react';

import { Icon } from '../icon';
import type { IconProps } from '../icon-props';

export const Circle = forwardRef<SVGSVGElement, IconProps>(function Circle(props, ref) {
  return (
    <Icon {...props} ref={ref}>
      <path fill='none' d='M0 0h256v256H0z' />
      <circle
        cx={128}
        cy={128}
        r={96}
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={16}
      />
    </Icon>
  );
});
