import React from 'react';
import { Icon } from '../icon';
import type { IconProps } from '../icon-props';

export function Announce(props: IconProps) {
  return (
    <Icon {...props}>
      <path d='m0,0l256,0l0,256l-256,0l0,-256z' fill='none' />
      <circle
        strokeWidth={16}
        strokeLinejoin='round'
        strokeLinecap='round'
        stroke='currentColor'
        fill='none'
        r={96}
        cy={128}
        cx={128}
      />
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={16}
        d='M128 120 v 60'
      />
      <circle cx={128} cy={84} r={10} fill='currentColor' />
    </Icon>
  );
}
