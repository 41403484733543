import type { AriaProgressBarProps } from '@react-aria/progress';
import { useProgressBar } from '@react-aria/progress';
import React, { PropsWithChildren } from 'react';
import { css, compose } from '../';
import * as styles from './progress-bar.css';
import type { CSS } from '../';

export type ProgressBarProps = PropsWithChildren<
  AriaProgressBarProps & {
    /**
     * Height of progress bar
     */
    height?: CSS['height'];

    /**
     * Color of fills segments
     */
    fillColor?: CSS['backgroundColor'];

    /**
     * Under color of progress bar
     */
    trackColor?: CSS['backgroundColor'];

    /**
     * Radius of corner
     */
    radius?: CSS['borderRadius'];

    /**
     * Whether progress bar change smooth
     */
    animation?: boolean;
  }
>;

/**
 * @name
 * Progress bar
 *
 * @example
 * Render progress bar with rounded corner and 80%
 * <ProgressBar radius={20} value={80} />
 */
export function ProgressBar(props: ProgressBarProps) {
  const {
    label,
    showValueLabel = !!label,
    value,
    minValue = 0,
    maxValue = 100,
    height = '$size125',
    fillColor = 'black',
    trackColor = 'transparent',
    radius = '$none',
    animation = true,
  } = props;
  const { progressBarProps, labelProps } = useProgressBar(props);

  // Calculate the width of the progress bar as a percentage
  const percentage = (value - minValue) / (maxValue - minValue);
  const barWidth = `${Math.round(percentage * 100)}%`;

  return (
    <div {...progressBarProps}>
      <div className={css(styles.progressBarWrapper)()}>
        {label && <span {...labelProps}>{label}</span>}
        {showValueLabel && <span>{progressBarProps['aria-valuetext']}</span>}
      </div>
      <div className={compose(css({ backgroundColor: trackColor, borderRadius: radius, height }))}>
        <div
          className={compose(
            styles.progressBarVariants({
              animation,
            }),
            css({ backgroundColor: fillColor, borderRadius: radius, height }),
          )}
          style={{
            width: barWidth,
          }}
        />
      </div>
    </div>
  );
}
