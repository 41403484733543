import React from 'react';
import { Icon } from '../icon';
import type { IconProps } from '../icon-props';

export function Cross(props: IconProps) {
  return (
    <Icon {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={22}
        d='M 56 56 L 200 200'
        fill='currentColor'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={22}
        d='M 200 56 L 56 200'
        fill='currentColor'
      />
    </Icon>
  );
}
