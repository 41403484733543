export enum BookingStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  CONFIRMED = 'CONFIRMED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  CUSTOMER_CANCELLED = 'CUSTOMER_CANCELLED',
  BUSINESS_CANCELLED = 'BUSINESS_CANCELLED',
  REJECTED = 'REJECTED',
  CANCEL_SHORT_NOTICE = 'CANCEL_SHORT_NOTICE',
}

export enum PaymentMethods {
  NDIA = 'NDIA',
  PLAN = 'PLAN',
  SELF = 'SELF',
}

export enum PaymentStatus {
  INITIAL = 'INITIAL',
  READY_FOR_BILLING = 'READY_FOR_BILLING',
  SEND_TO_FINANCE = 'SEND_TO_FINANCE',
  PROCESSED = 'PROCESSED',
  BILLING_FAILED = 'BILLING_FAILED',
  WAIVED = 'WAIVED',
  NO_CHARGE = 'NO_CHARGE',
  REQUIRES_APPROVAL = 'REQUIRES_APPROVAL',
  REJECTED = 'REJECTED',
}

export enum WorkerStatus {
  FINDING = 'FINDING',
  PUBLISHED = 'PUBLISHED',
  CLAIMED = 'CLAIMED',
  REJECTED = 'REJECTED',
  NO_WORKER = 'NO_WORKER',
  FOUND = 'FOUND',
}

export enum BillingPaymentStatus {
  INITIAL = 'INITIAL',
  REQUIRES_APPROVAL = 'REQUIRES_APPROVAL',
  READY_FOR_BILLING = 'READY_FOR_BILLING',
  SEND_TO_FINANCE = 'SEND_TO_FINANCE',
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  WAIVED = 'WAIVED',
  NO_CHARGE = 'NO_CHARGE',
}

export enum NdisSupportItemDateType {
  ABT = 'ABT',
  TR = 'TR',
}

export enum ServiceRecurringType {
  ONEOFF = 'ONEOFF',
  RECURRING = 'RECURRING',
}

export enum ServiceLocationType {
  FIXEDVENUE = 'FIXEDVENUE',
  ONLINE = 'ONLINE',
  CUSTOMERLOCATION = 'CUSTOMERLOCATION',
}

export enum ServiceWorkerSelectionType {
  SELECTABLE = 'SELECTABLE',
  ASSIGNED = 'ASSIGNED',
}

export enum ServicePublishStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum ServiceType {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
}

export enum ServiceTimeType {
  FIXEDTIME = 'FIXEDTIME',
  OPENTIME = 'OPENTIME',
}

export enum NdisSupportTypes {
  CORE = 'CORE',
  CAPITAL = 'CAPITAL',
  CB = 'CAPACITY BUILDING',
}

export enum MessageChannelType {
  IndividualChat = 1,
  GroupChat,
  ProviderToConsumer,
  ProviderToWorker,
  Enquiry,
}

export enum RecurringBookingPattern {
  EveryDay = 1,
  EveryWeek,
  EveryFortnight,
  EveryFourWeeks,
}

export enum EditRecurringMode {
  Current = 1, // current attendance
  CurrentAll, // current attendance and all future attendance
  CurrentNext, // current attendance and number of attendance next
  Upcoming, // all attendance that booking status is CONFIRMED, PENDING or ACCEPTED
  AllWorkerUpcoming, // all attendance for all worker that booking status is CONFIRMED, PENDING or ACCEPTED
  AllWorkerCurrentAll, // all attendance for all worker that
}

export enum DoneScreenType {
  SUBSCRIPTION_SCREEN = 'SUBSCRIPTION_SCREEN',
  LOG_IN_SCREEN = 'LOG_IN_SCREEN',
}

export enum TypeScreen {
  REGISTER_USER = 'REGISTER_USER',
  SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS',
}

export enum TypeDescription {
  NDIS = 'NDIS',
  ABN = 'ABN',
}

export enum EmailAlreadyExistType {
  ALREADY_LINKED = 'ALREADY_LINKED',
  WAITING_TO_CREATE_ORGANISATION = 'WAITING_TO_CREATE_ORGANISATION',
}

export enum TypeErrorCode {
  WRONG_PASSWORD = 'auth/wrong-password',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
  USER_NOT_FOUND = 'auth/user-not-found',
}
