import React, { type FocusEvent } from 'react';
import { type AriaFieldProps } from '@react-aria/label';

import { CountryFlag } from 'registration-flow-v2/icon';
import { type IValidations } from 'registration-flow-v2/types';
import { countryCodePhonePrefix, countryCodes, type CountryCode } from 'variables/locales';
import { type BoxProps } from '../box';
import { InputGroup } from '../input-group';
import { Select } from '../select';
import { Text } from '../text';
import { TextField } from '../text-field';
import { InputField } from '../input-field';

type PhoneInputFieldProps = Omit<AriaFieldProps, 'value' | 'onChange' | 'onBlur'> &
  Omit<BoxProps, 'value' | 'onChange' | 'onBlur'> & {
    countryCode: CountryCode;
    onChangeCountryCode: (countryCode: CountryCode) => void;
    value: string;
    onChange: (phoneNumber: string) => void;
    onBlur?: (e: FocusEvent) => void;
    validations?: IValidations;
    name: string;
    placeholder: string;
  };

export const PhoneInputField = ({
  countryCode,
  onChangeCountryCode,
  value,
  onChange,
  onBlur,
  validations,
  name,
  placeholder,
  ...inputFieldProps
}: PhoneInputFieldProps) => {
  return (
    <InputField {...inputFieldProps}>
      <InputGroup combineInput validations={validations}>
        <Select
          selectedKey={countryCode}
          onSelectionChange={onChangeCountryCode}
          isOnlyShowPrefix
          paddingTop='$space130'
          paddingBottom='$space130'
        >
          {countryCodes.map((countryCode) => (
            <Select.Item
              key={countryCode}
              prefix={<CountryFlag countryCode={countryCode} width='$size300' height='$size300' />}
            >
              {countryCode}
            </Select.Item>
          ))}
        </Select>
        <TextField
          prefix={
            <Text color='$muted' size='small'>
              {countryCodePhonePrefix[countryCode]}
            </Text>
          }
          paddingLeft='$none !important'
          name={name}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      </InputGroup>
    </InputField>
  );
};
