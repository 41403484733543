import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';
import { shadowValues } from 'registration-flow-v2/theme/tokens';

export const reset: CSS = {
  boxSizing: 'border-box',
  outline: 'none',
};

export const inputGroup: CSS = {
  display: 'flex',
  backgroundColor: '$white',
  borderRadius: '$small',

  '& > :first-child': {
    borderTopLeftRadius: '$small !important',
    borderBottomLeftRadius: '$small !important',
  },
  '& > :last-child': {
    borderTopRightRadius: '$small !important',
    borderBottomRightRadius: '$small !important',
  },
};

export const inputGroupVariants = css({
  outlineOffset: '-1px',

  variants: {
    combineInput: {
      true: {
        paddingLeft: '$medium',
        paddingRight: '$medium',
        borderStyle: '$solid',
        borderColor: '$neutral',
        borderWidth: '$border100',
        gap: '$space100',
      },
    },
    isHover: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.activeShadow,
      },
    },
    isFocus: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.activeShadow,
      },
    },
    isErrored: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.errorShadow,
      },
    },
    isDisabled: {
      true: {
        borderColor: 'transparent !important',
        backgroundColor: '$bodyLight2 !important',
        cursor: 'not-allowed !important',
        outlineColor: 'transparent !important',
        boxShadow: 'none !important',
      },
    },
  },
  compoundVariants: [
    {
      isErrored: true,
      combineInput: true,
      css: {
        outlineColor: '$critical',
      },
    },
  ],
});

export type inputGroupVariants = Stitches.VariantProps<typeof inputGroupVariants>;
