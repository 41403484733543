import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

// Empty Layout
class EmptyLayout extends Component<any, any> {
  // Prop types
  // static propTypes = {
  //   containerStyle: PropTypes.object,
  //   bordered: PropTypes.bool,
  //   padding: PropTypes.string,
  //   backgroundColor: PropTypes.string,
  //   className: PropTypes.string
  // };

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(EmptyLayout);
