import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import * as styles from './message.css';
import { compose, css, useStyleProps } from '../theme';
import { Positive, Announce, Critical } from '../icon';

import type { MessageVariants } from './message.css';
import type { TextStyleProps } from '../theme';
import type { HTMLProps, SlotProps } from '../types';
import type { PropsWithChildren } from 'react';

export type MessageProps = PropsWithChildren<HTMLProps<HTMLLabelElement>> &
  SlotProps &
  TextStyleProps &
  MessageVariants;

export const Message = forwardRef<HTMLSpanElement, MessageProps>(function Message(props, ref) {
  const { styleProps, ...otherProps } = useStyleProps(props);
  const { children, tone = 'default' } = props;

  return (
    <span
      {...filterDOMProps(otherProps)}
      ref={ref}
      className={compose(css(styles.reset, styles.base, styleProps), styles.variants({ tone }))}
    >
      <span className={css(styles.icon)()}>
        {
          {
            default: null,
            normal: <Announce minWidth='$size160' width='$size160' height='$size160' />,
            critical: <Critical minWidth='$size160' width='$size160' height='$size160' />,
            positive: <Positive minWidth='$size160' width='$size160' height='$size160' />,
          }[tone as 'default' | 'critical' | 'positive' | 'normal']
        }
      </span>
      {children}
    </span>
  );
});
