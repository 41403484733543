import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import * as styles from './split.css';
import { boxStyleProps, compose, css, useStyleProps } from '..';

import type { BoxStyleProps, CSS, HTMLProps, SlotProps } from '..';
import type { PropsWithChildren } from 'react';
import type { SplitVariants } from './split.css';

export type SplitProps = SlotProps &
  HTMLProps<HTMLDivElement> &
  BoxStyleProps &
  SplitVariants &
  PropsWithChildren<{
    collapseAt?: any;
  }>;

/**
 * @name
 * Split
 *
 * @description
 * The Split component is designed to create a split layout based on
 * a fractional ratio. The Split component will enforce a standard
 * spacing scheme through the gap prop and will optionally
 * switch to a stack layout when the provided threshhold is reached.
 */
export const Split = forwardRef<HTMLDivElement, SplitProps>(function Split(props, ref) {
  const { styleProps, ...otherProps } = useStyleProps(props, boxStyleProps);
  const { asChild, children, fraction, collapseAt } = props;
  const ElementType = asChild ? Slot : 'div';

  const collapseAtStyles = collapseAt
    ? ({
        [`@media(max-width: ${collapseAt})`]: {
          flexDirection: 'column',
        },
      } as CSS)
    : ({} as CSS);

  return (
    <ElementType
      {...filterDOMProps(otherProps)}
      className={compose(
        css(styles.reset, styles.split),
        css(styleProps),
        css(collapseAtStyles),
        styles.variants({ fraction }),
      )}
      ref={ref}
    >
      {children}
    </ElementType>
  );
});
