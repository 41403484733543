/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Switch, withRouter } from 'react-router-dom';
import { Typography } from 'antd';

// Routes
import Routes from 'routes';

import firebaseApp from 'stores/firebase-app';
import { Card, ProgressBar } from '@blueprintjs/core';

import AppContainer from 'layouts/AppContainer';
import EmptyLayout from 'layouts/EmptyLayout';

// React hot reloader
import _ from 'lodash';
import { loginPath } from 'utilities/navigation';
import { loginAndRedirectToZendesk, loginViaAuthService } from 'utilities/auth';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { message } from 'antd';

const Loading = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Card style={{ width: '25%', textAlign: 'center' }}>
      <div style={{ marginBottom: '16px' }}>
        <Typography.Text style={{ fontSize: '16px' }}>Loading...</Typography.Text>
      </div>
      <ProgressBar animate={true} />
    </Card>
  </div>
);

class App extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.setAppLoaded(false);
    firebaseApp.attachAuthListener(this._handleAuthStateChange);

    // ! create store listener
  }

  componentWillMount() {}

  componentWillUnmount = () => {};

  _onClickBadge = () => {
    const { history, doResetNotificationBadgeCount } = this.props;
    doResetNotificationBadgeCount();
    history.push('/notifications');
  };

  _onClickMessage = () => {
    const { history } = this.props;
    history.push('/messaging');
  };

  // Handles the Auth state changes. This is triggered whenever someone logs in / out.
  _handleAuthStateChange = async (state) => {
    const { doSignOutUsers } = this.props;
    let returnTo: string | null;

    const { location } = this.props;
    const { pathname } = this.props.history.location;

    const data = new URLSearchParams(location.search);

    // returnTo is for zendesk redirect
    returnTo = data.get('return_to');
    if (state != null) {
      // firebase authenticated
      if (
        pathname !== '/register' &&
        pathname !== '/invitation' &&
        pathname !== '/create-account/profile' &&
        pathname !== '/create-account/company-details'
      ) {
        if (returnTo) return await loginAndRedirectToZendesk({ zendeskRedirectUrl: returnTo });
        if (pathname === '/' || pathname === '/login' || pathname === '/reset-password') {
          return await loginViaAuthService({ doSignOutUsers });
        }
        return;
      }
    } else {
      // not authenticated
      // prevent push to login if the route is already login, to keep the params inside the url
      if (
        pathname !== '/login' &&
        pathname !== '/register' &&
        pathname !== '/invitation' &&
        pathname !== '/providers' &&
        pathname !== '/create-account/profile' &&
        pathname !== '/create-account/company-details' &&
        pathname !== '/reset-password' &&
        pathname !== '/mantine/test'
      ) {
        this.props['history'].push(loginPath());
      }
    }
    this.props.setAppLoaded(true);
  };

  render() {
    const { authStore, badgeCount, messagingBadgeCount } = this.props;

    // Waiting for initialization
    if (!authStore.isAppLoaded) {
      return <Loading />;
    } else {
      const { portalUser } = authStore;

      if (!portalUser) {
        // Default view
        return (
          <Switch>
            <Routes />
          </Switch>
        );
      } else {
        const { pathname } = this.props.history.location;
        switch (pathname) {
          case '/pdf':
          case '/redirect': {
            return (
              <EmptyLayout>
                <Switch>
                  <Routes />
                </Switch>
              </EmptyLayout>
            );
          }
          case '/register':
          case '/login':
          case '/sign-in':
          case '/reset-password': {
            return (
              <Switch>
                <Routes />
              </Switch>
            );
          }
          default: {
            return (
              <AppContainer
                portalUser={portalUser}
                badgeCount={badgeCount}
                messagingBadgeCount={messagingBadgeCount}
                onClickBadge={this._onClickBadge}
                onClickMessage={this._onClickMessage}
              >
                <Switch>
                  <Routes />
                </Switch>
              </AppContainer>
            );
          }
        }
      }
    }
  }
}

const mapState = (state) => ({
  authStore: state.authStore,

  // notifications badge count

  // messages badge count
});

const mapDispatch = (dispatch) => ({
  setAppLoaded: dispatch.authStore.setAppLoaded,
  doSignOutUsers: dispatch.authStore.doSignOutUsers,
});

const connectDispatch = connect(mapState, mapDispatch);

export default compose(connectDispatch, withRouter, withLDConsumer())(App);
