import { CSS } from 'registration-flow-v2/theme/index';

export const registrationContainer: CSS = {
  position: 'relative',
  width: '100%',
  backgroundColor: '$canaryLight2',
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',

  '@large': {
    display: 'flex',
    minHeight: '100vh',
  },
  '@xlarge': {
    justifyContent: 'flex-end',
  },
  '@xxxlarge': {
    alignItems: 'stretch',
    justifyContent: 'center',
  },
};

// ---- Result View CSS ---
export const resultViewContainer: CSS = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  width: '100%',
  overflow: 'hidden',
  backgroundColor: '$yellow',
  zIndex: 10,
};

export const content: CSS = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
};

export const groupImage: CSS = {
  position: 'relative',
  width: '266px',
  height: '250px',
  marginBottom: '$space400',
};

export const ellipse: CSS = {
  position: 'absolute',
  bottom: 0,
};

export const imageStatus: CSS = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
};

export const imageGif: CSS = {
  height: 'auto',
  width: '$size2000',
};

export const womanComputer: CSS = {
  width: '180px',
};

export const textResultView: CSS = {
  fontFamily: '$sans',
  fontWeight: '$normal',
  fontSize: '$font400',
  lineHeight: '$leading800',
  textAlign: 'center',
  color: '$black',
  '@xsmall': {
    width: '358px',
  },
  '@medium': {
    width: '522px',
  },
  '@large': {
    width: '464px',
  },
  '@xxlarge': {
    width: '502px',
  },
  '@xxxlarge': {
    width: '490px',
  },
};

// ---- End Result View CSS ---
