import type { PropsWithChildren } from 'react';
import React from 'react';
import type { BaseStyleProps, HTMLProps, ButtonProps } from '../..';
import { Button, compose, css } from '../..';
import { ButtonProvider } from '../../button/button-provider';
import * as styles from './modal-footer.css';

export type ModalFooterProps = PropsWithChildren<
  BaseStyleProps &
    HTMLProps<HTMLDivElement> & {
      /**
       * Text for default 'OK button'
       */
      okText?: string;

      /**
       *  Text for default 'Cancel button'
       */
      cancelText?: string;

      /**
       * Specify a function that will be called when a user clicks the default OK button
       */
      onOk?(): void;

      /**
       *  Specify a function that will be called when a user clicks the default Cancel button
       *  Default is 'onClose'
       */
      onCancel?(): void;

      /**
       *  Default value is false
       */
      isLoading?: boolean;
    }
>;

/**
 * @name
 * Modal Footer
 *
 * @example
 * <ModalFooter>
 *    <div> Footer </div>
 * </ModalFooter>
 */
export const ModalFooter = (props: ModalFooterProps) => {
  const { children, okText, cancelText, onOk, onCancel, isLoading = false } = props;

  const buttonStyles: ButtonProps = {
    size: {
      '@initial': 'large',
      '@small': 'medium',
    },
  };

  const defaultFooter = (
    <div className={css(styles.modalFooterDefaultActions)()}>
      <Button emphasis="quiet" onPress={onCancel} isDisabled={isLoading}>
        {cancelText ?? 'Cancel'}
      </Button>
      <Button emphasis="filled" onPress={onOk} isLoading={isLoading}>
        {okText ?? 'OK'}
      </Button>
    </div>
  );

  if (children === null) {
    return null;
  }

  return (
    <div className={compose(css(styles.reset, styles.modalFooter))}>
      <ButtonProvider {...buttonStyles}>{children ?? defaultFooter}</ButtonProvider>
    </div>
  );
};
