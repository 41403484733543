import React from 'react';
import { Button, Text } from '@good/components';
import { Loader2Adjustable, Email } from '@good/icons';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { reactTrpc } from 'utilities/react-trpc';

type Props = { token: string };

const inviteErrorMapping = {
  NOT_FOUND: `We couldn't find the details of your invite. Please contact your administrator.`,
  EXPIRED: `The invitation has expired. Please contact your administrator`,
  ALREADY_ACCEPTED: 'The invitation has already been accepted',
  BAD_STATUS: `The invitation couldn't be accepted. Please contact your administrator`,
  INVALID_TOKEN: `We couldn't find the details of your invite. Please contact your administrator.`,
} as const;

export const InvitationAcceptButton = ({ token }: Props) => {
  const history = useHistory();
  const {
    data: result,
    isLoading,
    error,
    mutate: acceptInvite,
  } = reactTrpc.invitations.accept.useMutation({
    async onSuccess(data) {
      if (data.success) {
        await message.success('Invitation accepted');
        history.push('/login');
      }
    },
  });

  return (
    <div className="flex flex-col gap-4">
      <Button
        data-testid="accept-button"
        emphasis="fill"
        onClick={() => acceptInvite({ encryptedToken: token })}
        isDisabled={isLoading}
        aria-disabled={isLoading}
      >
        {isLoading ? (
          <Loader2Adjustable className="animate-spin" data-testid="loading-icon" />
        ) : (
          <Email className="stroke-2" data-testid="email-icon" />
        )}
        Accept invitation
      </Button>

      {result && !result.success && <Text>{inviteErrorMapping[result.error]}</Text>}
      {error && <Text>Something went wrong accepting the invitation. Please try again.</Text>}
    </div>
  );
};
