import type { CSS } from '../../theme';

export const reset: CSS = {
  boxSizing: 'border-box',
  outline: 'none',
};

export const modalFooter: CSS = {
  position: 'relative',
  fontFamily: '$primary',
  fontSize: '$font75',

  paddingTop: '$space200',
  paddingBottom: '$space250',

  borderBottomLeftRadius: '$xsmall',
  borderBottomRightRadius: '$xsmall',
  borderTopStyle: '$solid',
  borderTopColor: '$bodyLight2',
  borderTopWidth: '$border0',

  '@initial': {
    paddingLeft: '$space250',
    paddingRight: '$space250',
  },
  '@small': {
    borderTopWidth: '$border200',
    paddingBottom: '$space200',
    height: '76px',
    paddingLeft: '$space300',
    paddingRight: '$space300',
  },
};

export const modalFooterDefaultActions: CSS = {
  display: 'flex',
  gap: '$space200',

  '@initial': {
    justifyContent: 'space-around',
  },
  '@small': {
    justifyContent: 'flex-end',
  },
};
