import React from 'react';
import { css, Text } from 'registration-flow-v2';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import * as commonStyles from 'views/css/common-onboarding.css';
import ellipse from 'assets/ellipse.svg';
import WomanComputer from 'assets/woman-computer.svg';

const ScreenThankRegister = () => {
  const { invitation } = useSelector((state: IRootState) => ({
    invitation: state.authStore.invitation,
  }));
  return (
    <div className={css(commonStyles.resultViewContainer)()}>
      <div className={css(commonStyles.content)()}>
        <div className={css(commonStyles.groupImage)()}>
          <img src={ellipse} className={css(commonStyles.ellipse)()} />
          <img src={WomanComputer} className={css(commonStyles.imageStatus, commonStyles.womanComputer)()} />
        </div>
        <div className={css(commonStyles.textResultView)()}>
          Thanks{' '}
          <Text emphasis="bold" fontSize="inherit !important">
            {invitation?.firstName}!
          </Text>{' '}
          For the best experience, log in to the workspace on desktop to get started.
        </div>
      </div>
    </div>
  );
};

export default ScreenThankRegister;
