import { css } from '@stitches/react';

import type { CSS } from '../theme';
import type * as Stitches from '@stitches/react';

export const reset: CSS = {
  margin: 0,
  heading: 0,
  overflowWrap: 'break-word',
  textRendering: 'optimizeLegibility',
};

export const base = css({
  fontSize: '$small',
  lineHeight: '$small',
  fontFamily: '$primary',
  display: 'inline-flex',
  alignItems: 'flex-start',
  columnGap: '$space50',
});

export const icon = css({
  marginTop: '$space20',
});

export const variants = css({
  color: '$$messageColor',

  variants: {
    tone: {
      default: {
        $$messageColor: '$colors-muted',
      },
      normal: {
        $$messageColor: '$colors-muted',
      },
      critical: {
        $$messageColor: '$colors-critical',
      },
      positive: {
        $$messageColor: '$colors-positive',
      },
    },
  },
  defaultVariants: {
    tone: 'default',
  },
});

export type MessageVariants = Stitches.VariantProps<typeof variants>;
