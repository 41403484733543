import type * as Stitches from '@stitches/react';

import { css, CSS } from '../../theme';

export const reset: CSS = {
  listStyle: 'none',
  boxSizing: 'border-box',
  outline: 'none',
  marginBlockStart: '$space0',
  marginBlockEnd: '$space0',
  fontFamily: '$primary',
};

export const option: CSS = {
  justifyContent: 'justify-between',
  alignItems: 'center',
  display: 'flex',
  margin: '$space0',
  padding: '$space100',
  cursor: 'pointer',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  transition: '$transition100',
  gap: '$space65',
};

export const optionVariants = css({
  backgroundColor: '$$optionBackgroundColor',
  fontWeight: '$$optionFontWeight',

  variants: {
    isSelected: {
      true: {
        $$optionFontWeight: '$fontWeights-bold',
      },
    },
    isFocused: {
      true: {
        $$optionBackgroundColor: '$colors-blueLight1',
      },
    },
  },
});

export type OptionsVariants = Stitches.VariantProps<typeof optionVariants>;
