import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router, Switch } from 'react-router-dom';

import { MantineProvider } from '@good/ui/core';

import { AuthServiceTrpcProvider } from 'providers/auth-service-trpc-provider';
import { history } from 'utilities/history';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { providerConfig } from 'integrations/launch-darkly';

// blueprint css include
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';

import './theme';

// React datepicker
import 'react-datepicker/dist/react-datepicker.css';
import './theme/react-datepicker-override.css';

// App bootstrapper
import App from './App';

// Persist Gate
import Gate from 'stores/rematch/components/Gate';

// Service worker. Probably can remove later.
import * as serviceWorker from './serviceWorker';

import { goodTheme } from '@good/ui/theme';
import { Notifications } from '@good/ui/notifications';

import './index.css';

import '@good/ui/core/styles.css';
import '@good/ui/dates/styles.css';
import '@good/ui/notifications/styles.css';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Gate>
    <MantineProvider theme={goodTheme}>
      <Notifications />
      <AuthServiceTrpcProvider>
        <LDProvider {...providerConfig}>
          <Router history={history}>
            <Switch>
              <App />
            </Switch>
          </Router>
        </LDProvider>
      </AuthServiceTrpcProvider>
    </MantineProvider>
  </Gate>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
