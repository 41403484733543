import React, { RefObject, useRef } from 'react';
import type { AriaListBoxOptions } from '@react-aria/listbox';
import { useListBox } from '@react-aria/listbox';
import { filterDOMProps } from '@react-aria/utils';
import type { ListState } from '@react-stately/list';
import { boxStyleProps, useStyleProps, compose, css } from '../theme';
import * as styles from './list-box.css';
import { ListBoxOption } from './option';

export type ListBoxProps = AriaListBoxOptions<unknown> & {
  listBoxRef?: RefObject<HTMLUListElement>;
  state: ListState<unknown>;
};

/**
 * @name
 * ListBox
 *
 * @props
 * (required) state: ListState created by `@react-stately/list`
 *
 * @description
 * Component use for create select
 *
 * @example
 * Use with `useSelect` and `useSelectState`
 * <ListBox {...menuProps} state={state} />
 *
 */
export function ListBox(props: ListBoxProps) {
  const ref = useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state } = props;

  const { listBoxProps } = useListBox(props, state, listBoxRef);
  const { styleProps, ...otherProps } = useStyleProps(props, boxStyleProps);

  /**
   * Need below code because current tsconfig file not config loop through React-aria's collections ( relate to 'downlevelIteration' flag )
   * Can use [...state.collection] instead if adding 'downlevelIteration' flag
   */
  const itemList = [];
  let currentKey = state.collection.getFirstKey();
  while (currentKey) {
    itemList.push(state.collection.getItem(currentKey));
    currentKey = state.collection.getKeyAfter(currentKey);
  }

  return (
    <ul
      ref={listBoxRef}
      {...listBoxProps}
      {...filterDOMProps(otherProps)}
      className={compose(css(styles.reset, styles.listBox), css(styleProps))}
    >
      {itemList.map((item) => (
        <ListBoxOption key={item.key} item={item} state={state} />
      ))}
    </ul>
  );
}
