import React, { Component } from 'react';
import { Icon, Menu } from 'antd';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

class SideNavMenu extends Component<any, any> {
  state = {
    selectedKeys: [],
  };

  _handleClick = async (e) => {
    const { history } = this.props;
    const { key } = e;

    key !== 'no_redirect' && this.setState({ selectedKeys: [key] });
    // For now, it will navigate to the value of the key.
    // Not sure if there's a better way to do this.

    // if (key === '/bookings') {
    //   const { doResetBookingSearch } = this.props;
    //   doResetBookingSearch({});
    //   // const filter = [
    //   //   FilterUtils.bookingStatusToFilter('status', [
    //   //     'PENDING',
    //   //     'ACCEPTED',
    //   //     'CONFIRMED',
    //   //     'INPROGRESS',
    //   //     'CANCEL_SHORT_NOTICE'
    //   //   ])
    //   // ];
    //   // setBookingsFilter(filter);
    // }

    // TODO Change this to resolve the actual names instead
    key !== 'no_redirect' && history.push(key);
  };

  sendToZendesk = () => {
    // const { portalUser } = this.props;
    // const redirect = CommonUtils.getZendeskRedirectUrl(portalUser);
    // return window.open(`/redirect?to=${redirect}`, '_blank');
  };

  render() {
    return (
      <Menu theme='dark' mode='inline' onClick={this._handleClick} selectedKeys={this.state.selectedKeys}>
        <MenuItem key='/dashboard'>
          <Icon type='dashboard' />
          <span>Dashboard</span>
        </MenuItem>
        <MenuItem key='/bookings'>
          <Icon type='book' />
          <span>Bookings</span>
        </MenuItem>
        <MenuItem key='/team'>
          <Icon type='team' />
          <span>Team</span>
        </MenuItem>
        <MenuItem key='/customers'>
          <Icon type='contacts' />
          <span>Customers</span>
        </MenuItem>
        <MenuItem key='/services'>
          <Icon type='apartment' />
          <span>Services</span>
        </MenuItem>
        <MenuItem key='/messaging'>
          <Icon type='mail' />
          <span>Messaging</span>
        </MenuItem>
        {/*<MenuItem key={'/messages'}>*/}
        {/*  <Icon type={'message'} />*/}
        {/*  <span>Messaging</span>*/}
        {/*</MenuItem>*/}
        {/* TODO Do we really need this here?  */}
        <MenuItem key='/notifications'>
          <Icon type='bell' />
          <span>Notifications</span>
        </MenuItem>

        {/* Temporarily menu */}
        {/* <MenuItem key={'/bookings-approve'}>
          <Icon type={'audit'} />
          <span>Booking Approval</span>
        </MenuItem> */}
        {/* <SubMenu
          key="payment"
          title={
            <>
              <Icon type="money-collect" />
              <span>Payment</span>
            </>
          }
        > */}
        <MenuItem key='/payments'>
          <Icon type='bank' />
          <span>Payments</span>
        </MenuItem>

        {/* <MenuItem key={'/invoice'}>
            <Icon type={'wallet'} />
            <span>Invoice</span>
          </MenuItem>

          <MenuItem key={'/batch'}>
            <Icon type={'save'} />
            <span>History</span>
          </MenuItem> */}
        {/* </SubMenu> */}
        <SubMenu
          key='account'
          title={
            <>
              <Icon type='setting' />
              <span>Account</span>
            </>
          }
        >
          <MenuItem key='/my-profile'>
            <Icon type='idcard' />
            <span>My Profile</span>
          </MenuItem>

          <MenuItem key='/company'>
            <Icon type='project' />
            <span>Company Profile</span>
          </MenuItem>

          <MenuItem key='/user'>
            <Icon type='desktop' />
            <span>Invite Admins</span>
          </MenuItem>

          <MenuItem key='/invite-users'>
            <Icon type='user-add' />
            <span>Invite Workers</span>
          </MenuItem>
        </SubMenu>

        <MenuItem key='no_redirect' onClick={this.sendToZendesk}>
          <Icon type='profile' />
          <span>Support</span>
        </MenuItem>
      </Menu>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });
const mapDispatch = (dispatch: IRootDispatch) => ({});

export default connect(mapState, mapDispatch)(withRouter(SideNavMenu));
