import PaperPlane from 'assets/gif/paper-plane.gif';
import React from 'react';
import { css } from 'registration-flow-v2';
import * as styles from '../css/css-components/RequestSentSection.css';

const RequestSentSection = () => {
  return (
    <>
      <div className={css(styles.text)()}>
        We’ve requested access to the account owner on your behalf. You will receive an email invitation to&nbsp;
        <span className={css(styles.textBold)()}>GoodHuman</span> if they accept your request.
      </div>
      <div className={css(styles.artWordWrapper)()}>
        <img src={PaperPlane} className={css(styles.paperPlane)()} />
      </div>
    </>
  );
};

export default RequestSentSection;
